import React, { useEffect } from 'react';
import { Box, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import { UserModel } from '@softwareone/plugin-project-management-common';
import { JiraItem } from '@softwareone/plugin-project-management-react/src/components/features/jira/JiraItem';

export type ChangeDataJiraProject = {
  jiraProjectTemplate?: string;
  jiraProjectKey?: string;
  jiraProjectLeadName: string;
  jiraContributors: string[];
  jiraCustomers: string[];
  jiraAdministrators: string[];
  jiraVisibilityForSwoEmployees?: boolean;
};

type CreateJiraProjectProps = {
  onChange: (e: ChangeDataJiraProject) => void;
  setIsValid: (e: boolean) => void;
  isValid: boolean;
  jiraProjectName: string;
  displayJiraProjectKey: string;
  jiraTeamMembersValue: UserModel[];
  createJiraProjectForm: ChangeDataJiraProject;
  isChecked: boolean;
  setIsChecked: (e: boolean) => void;
  getTeamMembersForJira: () => void;
};

export const CreateJiraProject = ({
  onChange,
  setIsValid,
  isValid,
  jiraProjectName,
  displayJiraProjectKey,
  jiraTeamMembersValue,
  createJiraProjectForm,
  isChecked,
  setIsChecked,
  getTeamMembersForJira,
}: CreateJiraProjectProps) => {
  const confluenceSpaceKey = false;

  const handleValidityChange = (e: boolean) => {
    setIsValid(e);
  };

  const handleChecked = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (!isChecked) {
      setIsValid(true);
      return;
    }
    setIsValid(isValid);
  }, [isChecked]);

  const formattedValue: string[] = [];
  if (Array.isArray(jiraTeamMembersValue))
    (jiraTeamMembersValue as UserModel[]).forEach(entry => {
      if (entry?.displayName !== undefined) formattedValue.push(entry.displayName);
    });

  const handleChange = (e: ChangeDataJiraProject) => {
    onChange({
      jiraProjectKey: e.jiraProjectKey,
      jiraProjectTemplate: e.jiraProjectTemplate,
      jiraProjectLeadName: e.jiraProjectLeadName,
      jiraAdministrators: e.jiraAdministrators,
      jiraContributors: e.jiraContributors,
      jiraCustomers: e.jiraCustomers,
      jiraVisibilityForSwoEmployees: e.jiraVisibilityForSwoEmployees,
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mr: 4,
      }}
    >
      <FormGroup>
        <FormControlLabel
          control={<Checkbox onChange={handleChecked} size="medium" checked={isChecked} />}
          label="Create Jira Project"
          style={{
            marginLeft: '0.5rem',
            marginBottom: '2rem',
            marginTop: '2rem',
          }}
        />
      </FormGroup>
      <JiraItem
        jiraProjectName={jiraProjectName}
        jiraProjectKey={createJiraProjectForm.jiraProjectKey ?? displayJiraProjectKey}
        jiraProjectTemplate={createJiraProjectForm.jiraProjectTemplate}
        jiraProjectLeadName={createJiraProjectForm.jiraProjectLeadName}
        jiraAdministrators={createJiraProjectForm.jiraAdministrators}
        withSharedConfluenceSpace={!!confluenceSpaceKey}
        jiraContributors={createJiraProjectForm.jiraContributors}
        jiraCustomers={createJiraProjectForm.jiraCustomers}
        userList={formattedValue}
        onChange={handleChange}
        onValidityChange={handleValidityChange}
        jiraVisibilityForSwoEmployees={createJiraProjectForm.jiraVisibilityForSwoEmployees}
        isDisabled={!isChecked}
        getTeamMembersforJira={getTeamMembersForJira}
      />
    </Box>
  );
};
