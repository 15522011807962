import { z } from 'zod';
import { ProjectInfoSelect } from './features/projectInfo/ProjectInfoItem';
import { EntityFilter } from '@backstage/plugin-catalog-react';
import { CatalogFilters } from '../types';
import { RegExConstants } from '@softwareone/plugin-project-management-common/src/utils/regExConstants';

export const getFieldValidationRules = (projectType: string, userSelect: ProjectInfoSelect) => {
  const technologySchema = z.object({
    id: z.string(),
    type: z.string(),
    name: z.string(),
  });
  const industrySchema = z.object({
    'SIC Code': z.number(),
    Office: z.string(),
    'Industry Title': z.string(),
  });

  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  let fieldValidationRules;

  const invalidUrlFormatMessage = 'Invalid URL format';

  function validateProjectKeyState() {
    return z
      .string()
      .refine(value => value.length >= 1 && value.length <= 16, {
        message: 'Project Key must be between 1 and 16 characters',
      })
      .refine(value => value !== '', {
        message: 'Project Key must not be empty',
      });
  }

  function validateProjectTitle() {
    return z.string().refine(value => value.length >= 1 && value.length <= 500, {
      message: 'Project Title must be between 1 to 500 characters',
    });
  }

  function validateStringValue(value: string, message: string) {
    return z.string().refine(check => check === value, {
      message,
    });
  }

  switch (projectType) {
    case 'Customer':
      fieldValidationRules = z
        .object({
          projectType: z.string(),
          oneSalesID: z.string().refine(value => RegExConstants.ONE_SALES_ID_REGEX.test(value), {
            message: 'Invalid OneSales ID format',
          }),
          psaProjectId: z.string(),
          erpProjectNumber: z.string(),
          projectCustomer: z.string().refine(value => value.length >= 1 && value.length <= 16, {
            message: 'Customer name must be between 1 and 16 characters',
          }),
          projectTitle: validateProjectTitle(),
          projectKeyState: validateProjectKeyState(),
          projectDescription: z.string(),
          technologies: z.array(technologySchema).nonempty(),
          industries: z.array(industrySchema),
          url: z
            .string()
            .optional()
            .refine(value => value === undefined || value === '' || urlPattern.test(value), {
              message: invalidUrlFormatMessage,
            }),
        })
        .superRefine((arg, ctx) => {
          const { erpProjectNumber, psaProjectId } = arg;
          const psaProjectIdCheck = RegExConstants.PSA_PROJECT_ID_REGEX.test(psaProjectId);
          const erpProjectNumberCheck = RegExConstants.ERP_PROJECT_NUMBER_REGEX.test(erpProjectNumber);

          if ((erpProjectNumber && psaProjectId) || (!erpProjectNumber && !psaProjectId)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Either PSA ID or ERP Project Number should be filled',
              path: ['erpProjectNumber', 'psaProjectId'],
            });
            return;
          }

          if (!erpProjectNumber && !psaProjectIdCheck) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid PSA Project ID format',
              path: ['psaProjectId'],
            });
            return;
          }

          if (!psaProjectId && !erpProjectNumberCheck) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid ERP Project ID format',
              path: ['erpProjectNumber'],
            });
          }
        });
      break;
    case 'Internal':
      fieldValidationRules = z
        .object({
          projectType: z.string(),
          oneSalesID: z.string().refine(
            value => {
              return value === '' || RegExConstants.ONE_SALES_ID_REGEX.test(value);
            },
            {
              message: 'Format must match the above format or be empty',
            }
          ),
          psaProjectId: z.string(),
          erpProjectNumber: z.string(),
          projectCustomer: validateStringValue('swo', 'Customer must be "swo"'),
          projectTitle: validateProjectTitle(),
          projectKeyState: validateProjectKeyState(),
          projectDescription: z.string(),
          technologies: z.array(technologySchema).nonempty(),
          industries: z.array(industrySchema),
          url: z
            .string()
            .optional()
            .refine(value => value === undefined || value === '' || urlPattern.test(value), {
              message: invalidUrlFormatMessage,
            }),
        })
        .superRefine((arg, ctx) => {
          const { erpProjectNumber, psaProjectId } = arg;
          const psaProjectIdCheck = RegExConstants.PSA_PROJECT_ID_REGEX.test(psaProjectId);
          const erpProjectNumberCheck = RegExConstants.ERP_PROJECT_NUMBER_REGEX.test(erpProjectNumber);
          if (!erpProjectNumber && !psaProjectId) {
            return;
          }

          if (erpProjectNumber && psaProjectId) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Either PSA ID or ERP Project Number should be filled',
              path: ['erpProjectNumber', 'psaProjectId'],
            });
            return;
          }

          if (!erpProjectNumber && !psaProjectIdCheck) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid PSA Project ID format',
              path: ['psaProjectId'],
            });
            return;
          }

          if (!psaProjectId && !erpProjectNumberCheck) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid ERP Project ID format',
              path: ['erpProjectNumber'],
            });
          }
        });
      break;
    case 'Personal':
      fieldValidationRules = z.object({
        projectType: z.string(),
        oneSalesID: validateStringValue('', 'OneSales ID must be empty'),
        psaProjectId: validateStringValue('', 'PSA Project ID must be empty'),
        erpProjectNumber: validateStringValue('', 'ERP Project Number must be empty'),
        projectCustomer: validateStringValue('personal', 'Customer must be "personal"'),
        projectTitle: validateProjectTitle(),
        projectKeyState: validateProjectKeyState(),
        projectDescription: z.string(),
        technologies: z.array(technologySchema).nonempty(),
        industries: z.array(industrySchema),
        url: z
          .string()
          .optional()
          .refine(value => value === undefined || value === '' || urlPattern.test(value), {
            message: invalidUrlFormatMessage,
          }),
      });
      break;
    default:
      throw new Error('Invalid projectType');
  }
  return fieldValidationRules.parse(userSelect);
};

export const reduceCatalogFilters = (filters: EntityFilter[]): CatalogFilters => {
  const condensedFilters = filters.reduce<CatalogFilters['filter']>((compoundFilter, filter) => {
    return {
      ...compoundFilter,
      ...(filter.getCatalogFilters ? filter.getCatalogFilters() : {}),
    };
  }, {});
  return { filter: condensedFilters };
};
