import { EntityFilter } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';

export class EntityOwnerTeamFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  getCatalogFilters(): Record<string, string | string[]> {
    return { 'spec.owner': this.values };
  }
  filterEntity(entity: Entity): boolean {
    return this.values.some(v => entity.spec?.owner === v);
  }
  toQueryValue(): string[] {
    return this.values;
  }
}
