import { styled } from '@mui/system';

export const StyledRow = styled('div')({
  display: 'flex',
  borderBottom: '1px solid #ccc',
  padding: '8px 0',
  '&:last-child': {
    borderBottom: 'none',
  },
});

export const StyledField = styled('div')({
  flex: '1',
  textAlign: 'left',
});

export const StyledDataField = styled('div')({
  flex: '1',
  textAlign: 'right',
});
