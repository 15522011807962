import { HttpService } from './http-service';

export class SummarAIzeService {
  constructor(private readonly httpService: HttpService) {}

  public async getSummarAIzeConfig(projectKey: string, componentKey: string) {
    const apiUrl = `/projects/${projectKey}/components/${componentKey}/summaraize-integration-configuration`;
    const res = await this.httpService.get(apiUrl);
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.error);
    }
    return data;
  }
  public async updateSummarAIzeConfig(projectKey: string, componentKey: string, enabled: boolean) {
    const apiUrl = `/projects/${projectKey}/components/${componentKey}/summaraize-integration-configuration`;
    const res = await this.httpService.put(apiUrl, { enabled });
    if (!res.ok) {
      const result = await res.json();
      throw new Error(result.message);
    }
  }
}
