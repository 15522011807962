import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { StyledRow, StyledField, StyledDataField } from '../../styled/styles';
import { ChangeDataJiraProject } from '../CreateJiraProject';

interface TableJiraProjectProps {
  createJiraProjectForm: ChangeDataJiraProject;
}

export const TableJiraProject: React.FC<TableJiraProjectProps> = createJiraProjectForm => {
  const createDataJiraProject = (field: string, dataField: string | string[] | boolean | undefined) => {
    if (typeof dataField === 'boolean') {
      return { field, dataField: [dataField.toString()] };
    }
    return { field, dataField };
  };
  const jiraProjectKey = createJiraProjectForm.createJiraProjectForm.jiraProjectKey || '';
  const jiraProjectTemplate = createJiraProjectForm.createJiraProjectForm.jiraProjectTemplate || '';
  const rowsJiraProject = [
    createDataJiraProject('Jira Project Key', jiraProjectKey),
    createDataJiraProject('Jira Project Template', jiraProjectTemplate),
    createDataJiraProject('Jira Project Lead', createJiraProjectForm.createJiraProjectForm.jiraProjectLeadName),
    createDataJiraProject(
      'Jira Project Administrators',
      createJiraProjectForm.createJiraProjectForm.jiraAdministrators
    ),
    createDataJiraProject('Jira Project Contributors', createJiraProjectForm.createJiraProjectForm.jiraContributors),
    createDataJiraProject('Jira Project Customers', createJiraProjectForm.createJiraProjectForm.jiraCustomers),
    createDataJiraProject(
      'Visible to all swo employees',
      createJiraProjectForm.createJiraProjectForm.jiraVisibilityForSwoEmployees
    ),
  ];
  return (
    <Box>
      <Typography variant="h6">Jira Project</Typography>
      {rowsJiraProject.map(row => (
        <StyledRow key={row.field}>
          <StyledField>{row.field}</StyledField>
          <StyledDataField>{Array.isArray(row.dataField) ? row.dataField.join(', ') : row.dataField}</StyledDataField>
        </StyledRow>
      ))}
    </Box>
  );
};
