import React, { useEffect } from 'react';
import { Box, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import { GitHubItem } from '@softwareone/plugin-project-management-react/src/components/features/gitHub/GitHubItem';

export type ChangeDataGitHubRepo = {
  kind: string;
  type: string;
  lifecycle: string;
  contentType: string;
  componentName: string;
  suffix: string;
  template?: string;
  defaultBranch?: string;
  isFormValid: boolean;
  templateOptions?: string[];
};

type GitHubRepoProps = {
  onChange: (e: ChangeDataGitHubRepo) => void;
  setIsValid: (e: boolean) => void;
  isValid: boolean;
  createGitHubRepoForm: ChangeDataGitHubRepo;
  templateOptions: string[];
  templateOptionsLoading: boolean;
  isChecked: boolean;
  setIsCheked: (e: boolean) => void;
};

export const CreateGithubRepo = ({
  onChange,
  setIsValid,
  isValid,
  createGitHubRepoForm,
  templateOptions,
  templateOptionsLoading,
  isChecked = false,
  setIsCheked,
}: GitHubRepoProps) => {
  const handleCreateChange = (e: Omit<ChangeDataGitHubRepo, 'isFormValid'>) => {
    onChange({
      kind: e.kind,
      componentName: e.componentName,
      suffix: e.suffix,
      type: e.type,
      lifecycle: e.lifecycle,
      contentType: e.contentType,
      template: e.template,
      defaultBranch: e.defaultBranch,
      templateOptions: e.templateOptions,
      isFormValid: createGitHubRepoForm.isFormValid,
    });
  };

  const onCreateValidityChange = (e: boolean) => {
    setIsValid(e);
  };

  const handleChecked = () => {
    setIsCheked(!isChecked);
  };

  useEffect(() => {
    if (!isChecked) {
      setIsValid(true);
      return;
    }
    setIsValid(isValid);
  }, [isChecked]);

  const createProps = {
    propType: 'Create' as const,
    kind: createGitHubRepoForm.kind,
    componentName: createGitHubRepoForm.componentName,
    suffix: createGitHubRepoForm.suffix,
    type: createGitHubRepoForm.type,
    lifecycle: createGitHubRepoForm.lifecycle,
    contentType: createGitHubRepoForm.contentType,
    template: createGitHubRepoForm.template,
    defaultBranch: createGitHubRepoForm.defaultBranch,
    templateOptions: templateOptions,
    templateOptionsLoading,
    isDisabled: !isChecked,
    onChange: handleCreateChange,
    onValidityChange: onCreateValidityChange,
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 2,
        ml: 2,
      }}
    >
      <FormGroup>
        <FormControlLabel
          control={<Checkbox onChange={handleChecked} size="medium" checked={isChecked} />}
          label="Create GitHub Repo"
          style={{
            marginBottom: '2rem',
            marginTop: '2rem',
          }}
        />
      </FormGroup>
      <GitHubItem {...createProps} />
    </Box>
  );
};
