import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { alpha, Button, darken, lighten, withStyles } from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';
import { makeStyles } from '@material-ui/core/styles';

type RepoTableProps = {
  handleDelete: any;
  handleArchive: any;
  rows: Entity[] | undefined;
  withActions: boolean;
};

const StyledTableCell = withStyles(theme => ({
  root: {
    borderBottom: `1px solid
    ${
      theme.palette.type === 'light'
        ? lighten(alpha(theme.palette.divider, 1), 0.88)
        : darken(alpha(theme.palette.divider, 1), 0.68)
    }`,
  },
  head: {
    fontWeight: 700,
  },
}))(TableCell);

const actionsStyle = () => {
  return {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-around',
  };
};

const useStyles = makeStyles({
  root: {
    maxWidth: 650,
  },
});
export const RepoTable = ({ handleDelete, handleArchive, rows, withActions }: RepoTableProps) => {
  const classes = useStyles();
  const getRepoName = (annotation: Record<string, string> | undefined) => {
    return annotation ? annotation['github.com/project-slug'].split('/')[1] : '';
  };
  const clickHandler = async (repoName: string) => {
    if (!repoName) {
      return;
    }
    await handleDelete(repoName);
  };
  const archiveHandler = async (repoName: string, archived: boolean) => {
    if (!repoName) {
      return;
    }
    await handleArchive(repoName, !archived);
  };

  return (
    <TableContainer className={classes.root} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Repository</StyledTableCell>
            {withActions && <StyledTableCell align="justify">Actions</StyledTableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map(row => (
            <TableRow key={getRepoName(row.metadata?.annotations)}>
              <StyledTableCell component="th" scope="row">
                {getRepoName(row.metadata?.annotations)}
              </StyledTableCell>
              {withActions && (
                <StyledTableCell align="justify" style={actionsStyle()}>
                  <Button variant="contained" onClick={() => clickHandler(getRepoName(row.metadata?.annotations))}>
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() =>
                      archiveHandler(getRepoName(row.metadata?.annotations), row.metadata?.archived as boolean)
                    }
                  >
                    {row.metadata.archived ? 'Unarchive' : 'Archive'}
                  </Button>
                </StyledTableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
