import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { FlexContainer, InfoToolTip } from '../../infoToolTip/InfoToolTip';

type Row = {
  time: string;
  username: string;
  qualityGate: string;
  oldValue: string;
  newValue: string;
};

type Props = {
  rows: Row[];
};

const fontTableHeader = {
  fontSize: '18px',
};
const tableRowStyle = {
  borderBottom: '1px solid #e0e0e0',
};

export const AuditLogsTableItem: React.FC<Props> = ({ rows }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Stack spacing={2}>
        <FlexContainer>
          <Box sx={{ ml: 1, mt: 2 }}>
            <Typography variant="h6">Audit logs</Typography>
          </Box>
          <Box sx={{ ml: 2, mt: 2 }}>
            <InfoToolTip text={'Audit logs history '} />
          </Box>
        </FlexContainer>
        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableHead style={tableRowStyle}>
              <TableRow>
                <TableCell style={fontTableHeader}>Date</TableCell>
                <TableCell style={fontTableHeader}>User</TableCell>
                <TableCell style={fontTableHeader}>Quality Gate</TableCell>
                <TableCell style={fontTableHeader}>Old Value</TableCell>
                <TableCell style={fontTableHeader}>New Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.time}</TableCell>
                  <TableCell>{row.username}</TableCell>
                  <TableCell>{row.qualityGate}</TableCell>
                  <TableCell>{row.oldValue}</TableCell>
                  <TableCell>{row.newValue}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  );
};
