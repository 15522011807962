import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { StyledRow, StyledField, StyledDataField } from '../../styled/styles';
import { ChangeDataGitHubRepo } from '../CreateGithubRepo';
import { ContentType } from '@softwareone/plugin-project-management-common/src/types/github/configuration/contentType';

interface TableGitHubRepoProps {
  createGitHubRepoForm: ChangeDataGitHubRepo;
}

export const TableGitHubRepo: React.FC<TableGitHubRepoProps> = createGitHubRepoForm => {
  const createDataGitHubRepo = (field: string, dataField: string | string[] | undefined) => {
    return { field, dataField };
  };

  const rowsGitHubRepo = [
    createDataGitHubRepo('Kind', createGitHubRepoForm.createGitHubRepoForm.kind),
    createDataGitHubRepo('Component Name', createGitHubRepoForm.createGitHubRepoForm.componentName),
    createDataGitHubRepo('Suffix', createGitHubRepoForm.createGitHubRepoForm.suffix),
    createDataGitHubRepo('Type', createGitHubRepoForm.createGitHubRepoForm.type),
    createDataGitHubRepo('Lifecycle', createGitHubRepoForm.createGitHubRepoForm.lifecycle),
    createDataGitHubRepo('Content Type', createGitHubRepoForm.createGitHubRepoForm.contentType),
    createDataGitHubRepo(ContentType.DEFAULT_BRANCH, createGitHubRepoForm.createGitHubRepoForm.defaultBranch),
    createDataGitHubRepo('Selected Template', createGitHubRepoForm.createGitHubRepoForm.template),
  ];
  return (
    <Box>
      <Typography variant="h6">GitHub Repo</Typography>
      {rowsGitHubRepo.map(row => {
        const { contentType } = createGitHubRepoForm.createGitHubRepoForm;
        if (
          (row.field === 'Selected Template' &&
            (contentType === ContentType.EMPTY || contentType === ContentType.DEFAULT_BRANCH)) ||
          (row.field === ContentType.DEFAULT_BRANCH &&
            (contentType === ContentType.EMPTY || contentType === ContentType.FROM_TEMPLATE))
        ) {
          return null;
        }
        return (
          <StyledRow key={row.field}>
            <StyledField>{row.field}</StyledField>
            <StyledDataField>{row.dataField}</StyledDataField>
          </StyledRow>
        );
      })}
    </Box>
  );
};
