import React, { useState } from 'react';
import { CreateProps } from './GitHubProps';
import TextField from '@mui/material/TextField';
import { z } from 'zod';
import { FormControl, InputLabel, MenuItem, Select, Box } from '@mui/material';
import { FormHelperText } from '@material-ui/core';
import Stack from '@mui/material/Stack';

import { AlertDisplay, Progress } from '@backstage/core-components';
import { FlexContainer, InfoToolTip, InfoToolTipList } from '../../infoToolTip/InfoToolTip';
import { ComponentType } from '@softwareone/plugin-project-management-common/src/types/component-management/componentType';
import { ContentType } from '@softwareone/plugin-project-management-common/src/types/github/configuration/contentType';

const kindOptions = ['Component', 'API'];

const lifecycleOptions = ['experimental', 'production', 'deprecated'];

export const CreateItem = ({
  kind,
  componentName,
  suffix,
  lifecycle,
  contentType,
  isDisabled,
  onChange,
  template,
  defaultBranch = 'main',
  onValidityChange,
  templateOptions,
  templateOptionsLoading,
  ...restProps
}: CreateProps) => {
  type Key = keyof Omit<CreateProps, 'onChange' | 'isDisabled' | 'propType' | 'onValidityChange'>;
  type ErrorMessage = {
    [p: string]: string | undefined;
  };
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();

  const validationSchema = z
    .object({
      kind: z.string().refine(val => kindOptions.includes(val)),
      componentName: z
        .string()
        .min(1, { message: 'Must not be empty' })
        .max(32, { message: 'Must be less than 32 characters' }),
      suffix: z.string().optional(),
      contentType: z.string().refine(val => Object.values(ContentType).includes(val as ContentType)),
      template: z.string().optional(),
      defaultBranch: z.string().min(1, { message: 'Default Branch must not be empty' }).optional(),
      lifecycle: z.string().refine(val => lifecycleOptions.includes(val)),
      type: z.string().refine(val => Object.values(ComponentType).includes(val as ComponentType), {
        message: 'Must be a valid type',
      }),
    })
    .refine(schema => {
      if (schema.contentType === ContentType.FROM_TEMPLATE) {
        return templateOptions?.includes(schema.template || '');
      }
      return true;
    })
    .refine(schema => {
      if (schema.contentType === ContentType.DEFAULT_BRANCH) {
        return (schema.defaultBranch?.trim().length ?? 0) > 0;
      }
      return true;
    });

  const handleChange = (key: Key, value: string) => {
    const formData = {
      kind,
      componentName,
      suffix,
      lifecycle,
      template: contentType !== ContentType.FROM_TEMPLATE ? undefined : template,
      defaultBranch: contentType === ContentType.DEFAULT_BRANCH ? 'main' : undefined,
      type: restProps.type,
      contentType,
      [key]: value,
    };
    if (key === 'contentType' && value === ContentType.DEFAULT_BRANCH) {
      formData.defaultBranch = 'main';
    }
    onChange(formData);
    const validationResult = validationSchema.safeParse(formData);
    onValidityChange(validationResult.success);
    if (!validationResult.success) {
      const validationObj: Record<string, string> = {};
      validationResult.error.issues
        .map(el => ({ [el.path[0]]: el.message }))
        .forEach(el => {
          Object.assign(validationObj, el);
        });
      setErrorMessage(prevState => ({
        ...prevState,
        [key]: validationObj[key],
      }));
      return;
    }
    setErrorMessage({});
  };
  return (
    <>
      <AlertDisplay />
      <Stack spacing={4} sx={{ marginBottom: 3 }}>
        <FlexContainer>
          <FormControl fullWidth required sx={{ m: 1 }}>
            <InputLabel id="kind-simple-select-label">Kind</InputLabel>
            <Select
              error={!!errorMessage?.kind}
              onChange={e => {
                handleChange('kind', e.target.value);
              }}
              value={kind}
              disabled={isDisabled}
              labelId="kind-simple-select-label"
            >
              {kindOptions.map(opt => {
                return (
                  <MenuItem key={opt} value={opt}>
                    {opt}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>{errorMessage?.kind}</FormHelperText>
          </FormControl>
          <Box sx={{ ml: 2 }}>
            <InfoToolTip text="Determines if the component will be a regular component or an API component. Both entity types represent physical project components and are treated equally. API components are more suitable for project components which expose APIs used by other components. API entities are designed to contain the OpenAPI definition of the exposed API." />
          </Box>
        </FlexContainer>
        <FlexContainer>
          <TextField
            sx={{ m: 1 }}
            helperText={isDisabled ? '' : errorMessage?.componentName}
            error={isDisabled ? false : !!errorMessage?.componentName}
            onChange={e => handleChange('componentName', e.target.value)}
            value={componentName}
            fullWidth
            label="Component Name"
            required
            variant="outlined"
            disabled={isDisabled}
          />
          <Box sx={{ ml: 2 }}>
            <InfoToolTip
              text={
                'The base name of the component (e.g., “Salary Calculator”) that will be used in the internal identifier of the component and the name of its corresponding GitHub repository. The entered text will be converted to lowercase and all spaces will be replaced with underscores (“_”).\nThe GitHub repository name will be <customer>-<project key>-<component name>-<suffix>.\nValid format: any text containing at least 1 and at most 32 characters.'
              }
            />
          </Box>
        </FlexContainer>
        <FlexContainer>
          <TextField
            sx={{ m: 1 }}
            onChange={e => handleChange('suffix', e.target.value)}
            value={suffix}
            fullWidth
            disabled={isDisabled}
            label="Suffix (e.g java)"
            variant="outlined"
          />
          <Box sx={{ ml: 2 }}>
            <InfoToolTip
              text={
                'An optional suffix for extra information added after the name (e.g., “service”, “java”, “angular”, “script”). The entered text will be converted to lowercase and all spaces will be replaced with underscores (“_”).\nThe GitHub repository name will be <customer>-<project key>-<component name>-<suffix>.\nValid format: any text containing at most 32 characters.'
              }
            />
          </Box>
        </FlexContainer>
        <FlexContainer>
          <FormControl fullWidth required sx={{ m: 1 }}>
            <InputLabel id="type-simple-select-label">Type</InputLabel>
            <Select
              onChange={e => {
                handleChange('type', e.target.value);
              }}
              value={restProps.type}
              disabled={isDisabled}
              labelId="type-simple-select-label"
            >
              {Object.values(ComponentType)
                .filter(type => type !== ComponentType.ALL)
                .map(type => {
                  return (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <Box sx={{ ml: 2 }}>
            <InfoToolTip text="The purpose the component will serve." />
          </Box>
        </FlexContainer>
        <FlexContainer>
          <FormControl fullWidth required sx={{ m: 1 }}>
            <InputLabel id="lifecycle-simple-select-label">Lifecycle</InputLabel>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                PaperProps: {
                  style: {
                    maxHeight: 250,
                  },
                },
              }}
              onChange={e => {
                handleChange('lifecycle', e.target.value);
              }}
              value={lifecycle}
              disabled={isDisabled}
              labelId="lifecycle-simple-select-label"
            >
              {lifecycleOptions.map(opt => {
                return (
                  <MenuItem key={opt} value={opt}>
                    {opt}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box sx={{ ml: 2 }}>
            <InfoToolTip text="Choose what stage of its lifecycle the component is at." />
          </Box>
        </FlexContainer>
        <FlexContainer>
          <FormControl fullWidth required sx={{ m: 1 }}>
            <InputLabel id="contentType-simple-select-label">Content Type</InputLabel>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                PaperProps: {
                  style: {
                    maxHeight: 250,
                  },
                },
              }}
              onChange={e => {
                handleChange('contentType', e.target.value);
              }}
              value={contentType}
              disabled={isDisabled}
              labelId="contentType-simple-select-label"
            >
              {Object.values(ContentType).map(opt => {
                return (
                  <MenuItem key={opt} value={opt}>
                    {opt}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box sx={{ ml: 2 }}>
            <InfoToolTipList
              text={
                'Choose what the repository will contain initially:\nEmpty - the repository will be empty, with no branches - suitable for automated migrations to GitHub\nDefault Branch - a “main” branch will be created in the repository and set as default - suitable for immediate start of development\nFrom Template - the repository will be created from an existing template repository that you can select from the drop-down which will appear below.'
              }
            />
          </Box>
        </FlexContainer>

        {contentType === 'From Template' &&
          (templateOptionsLoading ? (
            <Progress />
          ) : (
            <FlexContainer>
              <FormControl fullWidth required sx={{ m: 1 }}>
                <InputLabel id="template-simple-select-label">Select Template</InputLabel>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                      },
                    },
                  }}
                  onChange={e => {
                    handleChange('template', e.target.value);
                  }}
                  value={template || ''}
                  disabled={isDisabled}
                  labelId="template-simple-select-label"
                >
                  {templateOptions.map(opt => {
                    return (
                      <MenuItem key={opt} value={opt}>
                        {opt}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Box sx={{ ml: 2 }}>
                <InfoToolTip text="Choose a template" />
              </Box>
            </FlexContainer>
          ))}
        {contentType === 'Default Branch' && (
          <FlexContainer>
            <TextField
              sx={{ m: 1 }}
              helperText={isDisabled ? '' : errorMessage?.defaultBranch}
              error={isDisabled ? false : !!errorMessage?.defaultBranch}
              onChange={e => handleChange('defaultBranch', e.target.value)}
              value={defaultBranch}
              fullWidth
              disabled={isDisabled}
              label="Default Branch"
              variant="outlined"
            />
            <Box sx={{ ml: 2 }}>
              <InfoToolTip text={'The name of the default branch of the GitHub repository'} />
            </Box>
          </FlexContainer>
        )}
      </Stack>
    </>
  );
};
