export enum ComponentType {
  SERVICE = 'Service',
  WEBSITE = 'Website',
  LIBRARY = 'Library',
  WEB_APPLICATION = 'Web Application',
  MOBILE_APPLICATION = 'Mobile Application',
  BUSINESS_INTELLIGENCE = 'Business Intelligence',
  MACHINE_LEARNING = 'Machine Learning',
  AI = 'AI',
  FUNCTION = 'Function',
  LOW_CODE = 'Low Code',
  CMS = 'CMS',
  CRM = 'CRM',
  CLI = 'CLI',
  INFRASTRUCTURE_AS_CODE = 'Infrastructure as Code',
  AUTOMATED_TESTING = 'Automated Testing',
  DOCUMENTATION = 'Documentation',
  OTHER = 'Other',
  ALL = 'All',
}
