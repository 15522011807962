import useAsync from 'react-use/lib/useAsync';
import { useContext } from 'react';
import { AppContext } from '@softwareone/plugin-project-management-react';

export const useOrganizationTeamMembers = () => {
  const {
    services: { projectService },
  } = useContext(AppContext);
  const { loading, error, value } = useAsync(async () => projectService.getOrgUsers(), []);
  return { loading, error, value };
};
