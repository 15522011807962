import { genPageTheme, shapes } from '@backstage/theme';

const pageThemes = {
  home: genPageTheme({ colors: ['#0000ff', '#0000ff'], shape: shapes.wave }),
  documentation: genPageTheme({
    colors: ['#0000ff', '#0000ff'],
    shape: shapes.wave,
  }),
  tool: genPageTheme({ colors: ['#0000ff', '#0000ff'], shape: shapes.wave }),
  service: genPageTheme({
    colors: ['#0000ff', '#0000ff'],
    shape: shapes.wave,
  }),
  website: genPageTheme({
    colors: ['#0000ff', '#0000ff'],
    shape: shapes.wave,
  }),
  library: genPageTheme({
    colors: ['#0000ff', '#0000ff'],
    shape: shapes.wave,
  }),
  other: genPageTheme({ colors: ['#0000ff', '#0000ff'], shape: shapes.wave }),
  app: genPageTheme({ colors: ['#0000ff', '#0000ff'], shape: shapes.wave }),
  apis: genPageTheme({ colors: ['#0000ff', '#0000ff'], shape: shapes.wave }),
};
export default pageThemes;
