import React from 'react';
import { Snackbar, Theme } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

type AlertSnackbarProps = {
  open: boolean;
  onClose: any;
  severity: 'success' | 'error';
  message: string;
  autoHideDuration?: number;
};
export const AlertSnackbar = ({ open, onClose, severity, message, autoHideDuration = 8000 }: AlertSnackbarProps) => {
  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose} disableWindowBlurListener>
        <Alert severity={severity}>
          {message.split('\n').map((line, i) => (
            <p key={i}>{line}</p>
          ))}
        </Alert>
      </Snackbar>
    </div>
  );
};
