import React from 'react';
import { Box } from '@material-ui/core';
import LeadTimeChart from '../charts/LeadTimeChart';
import DeploymentFrequencyChart from '../charts/DeploymentFrequencyChart';

const MetricsCard = () => {
  return (
    <Box>
      <Box>
        <DeploymentFrequencyChart />
      </Box>
      <Box>
        <LeadTimeChart />
      </Box>
    </Box>
  );
};

export default MetricsCard;
