import React from 'react';
import { Content, PageWithHeader } from '@backstage/core-components';
import { EntityListProvider } from '@backstage/plugin-catalog-react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

export function PowerBiReports() {
  const url = useApi(configApiRef).getString('powerBiReport.url');
  return (
    <PageWithHeader title="Power BI Reports" themeId="home">
      <Content>
        <EntityListProvider>
          <iframe title="swodo-main" style={{ width: '100%', height: '100%', border: 'none' }} src={url}></iframe>
        </EntityListProvider>
      </Content>
    </PageWithHeader>
  );
}
