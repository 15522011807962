import React, { useCallback, useEffect, useState } from 'react';
import { ForkProps } from './GitHubProps';
import Stack from '@mui/material/Stack';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import TextField from '@mui/material/TextField';
import { z } from 'zod';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { ComponentType } from '@softwareone/plugin-project-management-common/src/types/component-management/componentType';

const kindOptions = ['Component', 'API'];

const lifecycleOptions = ['experimental', 'production', 'deprecated'];

interface Option {
  value: string;
  label: string;
}
export const ForkItem = ({
  sourceProjectKey,
  sourceComponentKey,
  kind,
  lifecycle,
  isDisabled,
  onChange,
  sourceProjectKeyOptions,
  onValidityChange,
  ...restProps
}: ForkProps) => {
  const [sourceComponentKeyOptions, setSourceComponentKeyOptions] = useState<Option[]>([]);
  const catalogApi = useApi(catalogApiRef);
  const validationSchema = z.object({
    sourceProjectKey: z.string().refine(val => sourceProjectKeyOptions.some(opt => opt.value === val)),
    sourceComponentKey: z.string().refine(val => sourceComponentKeyOptions.some(opt => opt.value === val)),
    kind: z.string().optional(),
    lifecycle: z.string().refine(val => lifecycleOptions.includes(val)),
    type: z.string().refine(val => Object.values(ComponentType).includes(val as ComponentType)),
  });

  type Key = keyof Omit<ForkProps, 'isDisabled' | 'onChange' | 'onValidityChange' | 'propType'>;

  const updateComponentsBySourceProjectKey = useCallback(async () => {
    const sourceComponents = [] as Option[];
    const sourceComponentResults = await catalogApi.getEntities({
      filter: {
        kind: ['API', 'Component'],
        'spec.system': [sourceProjectKey],
      },
    });

    sourceComponentResults.items.forEach(component => {
      sourceComponents.push({
        label: component.metadata.name,
        value: component.metadata.name,
      });
    });
    setSourceComponentKeyOptions(sourceComponents);
  }, [sourceProjectKey]);

  useEffect(() => {
    updateComponentsBySourceProjectKey();
  }, [updateComponentsBySourceProjectKey]);
  const handleChange = (key: Key, value: string) => {
    const formData = {
      sourceProjectKey,
      sourceComponentKey: key === 'sourceProjectKey' ? '' : sourceComponentKey,
      kind,
      lifecycle,
      type: restProps.type,
      [key]: value,
    };
    onChange(formData);
    onValidityChange(validationSchema.safeParse(formData).success);
  };
  return (
    <>
      <Stack spacing={4} sx={{ marginBottom: 3 }}>
        <Autocomplete
          options={sourceProjectKeyOptions}
          id="source-project-key"
          value={sourceProjectKeyOptions.find(item => item.value === sourceProjectKey) || null}
          getOptionLabel={(option: Option) => option.label}
          onChange={(_event: any, newValue: Option | null) => {
            handleChange('sourceProjectKey', newValue?.value as string);
          }}
          renderInput={params => <TextField required {...params} label="Source Projects" />}
          renderOption={(props, item) => <MenuItem {...props}>{item.label}</MenuItem>}
        />
        <FormControl fullWidth required sx={{ m: 1 }}>
          <InputLabel id="source-component-simple-select-label">Source Component</InputLabel>
          <Select
            onChange={e => {
              handleChange('sourceComponentKey', e.target.value);
            }}
            value={sourceComponentKeyOptions.find(opt => opt.value === sourceComponentKey)?.value || ''}
            disabled={isDisabled}
            labelId="source-component-simple-select-label"
          >
            {sourceComponentKeyOptions.map(opt => {
              return (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth required sx={{ m: 1 }}>
          <InputLabel id="kind-simple-select-label">Kind</InputLabel>
          <Select
            onChange={e => {
              handleChange('kind', e.target.value);
            }}
            value={kind}
            disabled={isDisabled}
            labelId="kind-simple-select-label"
          >
            {kindOptions.map(opt => {
              return (
                <MenuItem key={opt} value={opt}>
                  {opt}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth required sx={{ m: 1 }}>
          <InputLabel id="type-simple-select-label">Type</InputLabel>
          <Select
            onChange={e => {
              handleChange('type', e.target.value);
            }}
            value={restProps.type}
            disabled={isDisabled}
            labelId="type-simple-select-label"
          >
            {Object.values(ComponentType)
              .filter(type => type !== ComponentType.ALL)
              .map(type => {
                return (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl fullWidth required sx={{ m: 1 }}>
          <InputLabel id="lifecycle-simple-select-label">Lifecycle</InputLabel>
          <Select
            onChange={e => {
              handleChange('lifecycle', e.target.value);
            }}
            value={lifecycle}
            disabled={isDisabled}
            labelId="lifecycle-simple-select-label"
          >
            {lifecycleOptions.map(opt => {
              return (
                <MenuItem key={opt} value={opt}>
                  {opt}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Stack>
    </>
  );
};
