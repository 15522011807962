import { CreateJiraProject, UpdateJiraTeamMembers } from './types';
import { HttpService } from './http-service';

export class JiraService {
  constructor(private readonly httpService: HttpService) {}

  public async getTeamMembers(projectKey: string) {
    const res = await this.httpService.get(`/projects/${projectKey}/jira/teams`);
    return await res.json();
  }

  public async getJiraOrgUsers() {
    const res = await this.httpService.get('/projects/org/jira/teams');
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.message);
    }
    return data;
  }
  public async updateTeamMembers(updateTeamMembers: UpdateJiraTeamMembers) {
    const { projectKey, ...rest } = updateTeamMembers;
    const res = await this.httpService.put(`/projects/${projectKey}/jira/teams`, rest);
    if (!res.ok) {
      throw new Error(`Error! Status: ${res.status}`);
    }
  }

  public async createJiraProject(createJiraProject: CreateJiraProject) {
    const { projectKey, ...rest } = createJiraProject;
    const res = await this.httpService.post(`/projects/${projectKey}/jira/projects`, rest);
    if (!res.ok) {
      throw new Error(`Error! Status: ${res.status}`);
    }
  }

  public async checkJiraProjectKey(jiraProjectKey: string) {
    const res = await this.httpService.post('/projects/prevalidation/jira-project-key', { jiraProjectKey });
    if (res.status === 400 || res.status === 409) {
      const result = await res.json();
      throw new Error(result.message);
    }
  }
}
