import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { z } from 'zod';
import { Box, MenuItem, Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Stack from '@mui/material/Stack';
import { getFieldValidationRules } from '../../utils';
import { Industry, Technology } from '@softwareone/plugin-project-management-common';
import ProjectInfoUrlTable from './ProjectInfoUrlTable';
import { FlexContainer, InfoToolTip } from '../../infoToolTip/InfoToolTip';
import { UrlEntry } from '../../../services/types/UpdateProject';
import {
  ProjectStage,
  ServiceLine,
} from '@softwareone/plugin-project-management-common/src/types/backstage/catalog/system-entity/service-line';

interface ProjectInfoItemProps {
  isOwner?: boolean;
  createMode?: boolean;
  projectType: string;
  oneSalesID: string;
  psaProjectId: string;
  erpProjectNumber: string;
  projectCustomer: string;
  technologies: Technology[];
  industries: Industry[];
  technologyOptions: Technology[];
  industryOptions: Industry[];
  projectTitle: string;
  projectKeyState: string;
  projectDescription: string;
  url: string;
  urlTitle: string;
  urlCategory: string;
  onChange: (e: ProjectInfoSelect) => void;
  onValidityChange: (e: boolean) => void;
  handleAddUrl: () => void;
  deleteUrl: (indexToDelete: number) => void;
  urls: UrlEntry[];
  urlTitleError: boolean;
  serviceLines: ServiceLine[];
  selectedServiceLine: ServiceLine | null;
  setSelectedServiceLine: (stages: ServiceLine | null) => void;
  selectedValueServiceLineStage: ProjectStage | null;
  setSelectedValueServiceLineStage: (stages: ProjectStage | null) => void;
}

export type ProjectInfoSelect = Omit<
  ProjectInfoItemProps,
  | 'onChange'
  | 'isOwner'
  | 'onValidityChange'
  | 'createMode'
  | 'technologyOptions'
  | 'industryOptions'
  | 'handleAddUrl'
  | 'deleteUrl'
  | 'urls'
  | 'urlTitleError'
  | 'setSelectedServiceLine'
  | 'setSelectedValueServiceLineStage'
>;

export const ProjectInfoItem: React.FC<ProjectInfoItemProps> = ({
  onChange,
  projectType,
  oneSalesID,
  psaProjectId,
  projectCustomer,
  projectTitle,
  projectKeyState,
  technologies,
  industries,
  technologyOptions,
  industryOptions,
  projectDescription,
  isOwner,
  url,
  urlTitle,
  urlCategory,
  onValidityChange,
  createMode,
  deleteUrl,
  handleAddUrl,
  erpProjectNumber,
  urls,
  urlTitleError,
  serviceLines,
  selectedServiceLine,
  setSelectedServiceLine,
  selectedValueServiceLineStage,
  setSelectedValueServiceLineStage,
}) => {
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  const buttonStyle = {
    maxHeight: '55px',
  };
  type InputValue = Industry[] | Technology[] | ServiceLine[] | ProjectStage | string;
  const handleChange = (key: keyof ProjectInfoSelect, newValue: InputValue | null) => {
    if (newValue !== null) {
      const userSelect: ProjectInfoSelect = {
        projectType,
        oneSalesID,
        psaProjectId,
        projectCustomer,
        projectTitle,
        projectKeyState,
        projectDescription,
        erpProjectNumber,
        technologies,
        industries,
        url,
        urlTitle,
        urlCategory,
        [key]: newValue,
        serviceLines,
        selectedServiceLine,
        selectedValueServiceLineStage,
      };
      let isProjectTypeUpdate = false;
      let newValidationErrors = {} as { [key: string]: string };
      let updatedIsValid = true;

      const getDefaultProjectCustomerByProjectType = (projectTypeParam: string) => {
        switch (projectTypeParam) {
          case 'Internal':
            return 'swo';
          case 'Personal':
            return 'personal';
          case 'Customer':
            return '';
          default:
            return '';
        }
      };

      if (key === 'projectType' && typeof newValue === 'string') {
        isProjectTypeUpdate = true;
        userSelect.projectCustomer = getDefaultProjectCustomerByProjectType(newValue);
        if (newValue === 'Personal') {
          userSelect.psaProjectId = '';
          userSelect.oneSalesID = '';
          userSelect.erpProjectNumber = '';
        }
      }
      try {
        getFieldValidationRules(userSelect.projectType, userSelect);
      } catch (error: any) {
        if (error instanceof z.ZodError) {
          newValidationErrors = error.errors.reduce(
            (acc: any, err: z.ZodIssue) => {
              err.path.forEach(path => {
                acc[path] = err.message;
              });
              return acc;
            },
            {} as { [key: string]: string }
          );
          updatedIsValid = false;
        }
      }
      if (isProjectTypeUpdate) {
        setValidationErrors({});
        onValidityChange(updatedIsValid);
        onChange(userSelect);
        return;
      }
      setValidationErrors(prevState => {
        if (key === 'erpProjectNumber' || key === 'psaProjectId') {
          return {
            ...prevState,
            erpProjectNumber: newValidationErrors.erpProjectNumber,
            psaProjectId: newValidationErrors.psaProjectId,
            [key]: newValidationErrors[key],
          };
        }
        return {
          ...prevState,
          [key]: newValidationErrors[key],
        };
      });

      onValidityChange(updatedIsValid);
      onChange(userSelect);
    }
  };

  const projectTypeOptions = ['Customer', 'Internal', 'Personal'];

  const modifyIndustryOptions = () =>
    industryOptions.map(opt => ({
      ...opt,
      'Industry Title': `${opt['Industry Title']} (${opt['SIC Code']})`,
    }));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>
      <Stack spacing={4} sx={{ m: 4 }}>
        <FlexContainer>
          <Autocomplete
            options={projectTypeOptions}
            disabled={!createMode}
            value={projectType}
            onChange={(_e: any, newValue: string | null) => handleChange('projectType', newValue as string)}
            renderInput={params => <TextField {...params} label="Project Type" />}
            renderOption={(props, option) => (
              <MenuItem {...props} style={{ backgroundColor: props.style?.backgroundColor }}>
                {option}
              </MenuItem>
            )}
            sx={{ flex: 1, mr: 1 }}
          />
          <InfoToolTip
            text={
              'Customer - developed for an external customer.\nInternal - developed for SoftwareOne usage only.\nPersonal - developed by a single person or a team for training, research or experimental purposes. '
            }
          />
        </FlexContainer>
        <FlexContainer>
          <TextField
            name="oneSalesId"
            label="OneSales ID (e.g. ABC-2023-123456-1)"
            value={oneSalesID}
            disabled={!isOwner || projectType === 'Personal'}
            required={projectType === 'Customer'}
            onChange={e => handleChange('oneSalesID', e.target.value)}
            error={!!validationErrors.oneSalesID}
            helperText={validationErrors.oneSalesID}
            sx={{ flex: 1, mr: 1 }}
          />
          <InfoToolTip
            text={
              'The ID of the OneSales opportunity of the project.\nValid format: <three uppercase Latin letters>-<year>-<six-digit ID>-<one or more digits>'
            }
          />
        </FlexContainer>
        <FlexContainer>
          <TextField
            name="psaProjectId"
            label="PSA Project ID (e.g. ABC-PRJ-123456) **"
            value={psaProjectId}
            disabled={!isOwner || projectType === 'Personal'}
            onChange={e => handleChange('psaProjectId', e.target.value)}
            error={!!validationErrors.psaProjectId}
            helperText={validationErrors.psaProjectId}
            sx={{ flex: 1, mr: 1 }}
          />
          <InfoToolTip
            text={
              'The ID of the corresponding PSA project.\nValid format: <three uppercase Latin letters>-PRJ-<six-digit ID>'
            }
          />
        </FlexContainer>
        <FlexContainer>
          <TextField
            name="erpProjectNumber"
            label="ERP Project Number (e.g. AB[C]-PRJ-123456) **"
            value={erpProjectNumber}
            disabled={!isOwner || projectType === 'Personal'}
            onChange={e => handleChange('erpProjectNumber', e.target.value)}
            error={!!validationErrors.erpProjectNumber}
            helperText={validationErrors.erpProjectNumber}
            sx={{ flex: 1, mr: 1 }}
          />
          <InfoToolTip
            text={
              'The ERP number of the corresponding SWO Delivery Platform project.\nValid format: <two uppercase Latin letters>-PRJ-<six-digit ID>'
            }
          />
        </FlexContainer>
        <FlexContainer>
          <TextField
            name="projectCustomer"
            label="Customer"
            value={projectCustomer}
            required={projectType === 'Customer'}
            disabled={projectType === 'Internal' || projectType === 'Personal' || !createMode}
            onChange={e => handleChange('projectCustomer', e.target.value)}
            error={!!validationErrors.projectCustomer}
            helperText={validationErrors.projectCustomer}
            sx={{ flex: 1, mr: 1 }}
          />
          <InfoToolTip
            text={
              'The name of the customer which the project is developed for, editable only for customer projects..\nValid format: any text containing at least 1 and at most 16 characters.'
            }
          />
        </FlexContainer>
        <FlexContainer>
          <TextField
            name="projectTitle"
            label="Project Title"
            value={projectTitle}
            required={projectType === 'Customer' || projectType === 'Internal' || projectType === 'Personal'}
            disabled={!isOwner}
            onChange={e => handleChange('projectTitle', e.target.value)}
            error={!!validationErrors.projectTitle}
            helperText={validationErrors.projectTitle}
            sx={{ flex: 1, mr: 1 }}
          />
          <InfoToolTip
            text={
              'The full name of the project.\nValid format: any text containing at least 1 and at most 500 characters.'
            }
          />
        </FlexContainer>
        <FlexContainer>
          <TextField
            name="projectKeyState"
            label="Project Key"
            value={projectKeyState}
            required={projectType === 'Customer' || projectType === 'Internal' || projectType === 'Personal'}
            disabled={!createMode}
            onChange={e => handleChange('projectKeyState', e.target.value)}
            error={!!validationErrors.projectKeyState}
            helperText={validationErrors.projectKeyState}
            sx={{ flex: 1, mr: 1 }}
          />
          <InfoToolTip
            text={
              'A short identifier of the project that will be used in its internal IDs and all its GitHub repositories names.\nIt is recommended that you use a key based on the project title. The entered text will be converted to lowercase and all spaces will be replaced with underscores (“_”).\nValid format: any text containing at least 1 and at most 16 characters.'
            }
          />
        </FlexContainer>
        <FlexContainer>
          <TextField
            name="projectDescription"
            label="Project Description"
            value={projectDescription}
            disabled={!isOwner}
            onChange={e => handleChange('projectDescription', e.target.value)}
            error={!!validationErrors.projectDescription}
            helperText={validationErrors.projectDescription}
            sx={{ flex: 1, mr: 1 }}
          />
          <InfoToolTip
            text={
              'An optional description of the project that adds additional information.\nValid format: empty or any text.'
            }
          />
        </FlexContainer>
        <FlexContainer>
          <Autocomplete
            options={serviceLines}
            value={selectedServiceLine || null}
            getOptionLabel={option => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disabled={!isOwner}
            sx={{ flex: 1, mr: 1 }}
            onChange={(_e: any, newValue: ServiceLine | null) => {
              setSelectedServiceLine(newValue);
              handleChange('selectedServiceLine', newValue ? [newValue] : []);
            }}
            renderInput={params => <TextField {...params} label="Service Line " variant="outlined" />}
            renderOption={(props, option) => (
              <MenuItem {...props} style={{ backgroundColor: props.style?.backgroundColor }}>
                {option.name}
              </MenuItem>
            )}
          />
          <InfoToolTip text="Service Line" />
        </FlexContainer>
        <FlexContainer>
          <Autocomplete
            options={selectedServiceLine?.stages || []}
            value={selectedValueServiceLineStage || null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disabled={!isOwner}
            getOptionLabel={option => option.name}
            sx={{ flex: 1, mr: 1 }}
            onChange={(_e: any, newValue: ProjectStage | null) => {
              setSelectedValueServiceLineStage(newValue);
              handleChange('selectedValueServiceLineStage', newValue);
            }}
            renderInput={params => <TextField {...params} label="Stage " variant="outlined" />}
            renderOption={(props, option) => (
              <MenuItem {...props} style={{ backgroundColor: props.style?.backgroundColor }}>
                {option.name}
              </MenuItem>
            )}
          />
          <InfoToolTip text="Stage" />
        </FlexContainer>
        <FlexContainer>
          <Autocomplete
            options={technologyOptions.slice().sort((a, b) => a.type.localeCompare(b.type))}
            multiple
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disabled={!isOwner}
            getOptionLabel={option => option.name}
            value={technologies}
            groupBy={option => option.type}
            sx={{ flex: 1, mr: 1 }}
            onChange={(_e: any, newValue: Technology[] | null) =>
              handleChange('technologies', newValue as Technology[])
            }
            renderInput={params => <TextField {...params} label="Technologies *" variant="outlined" />}
            renderOption={(props, option) => (
              <MenuItem {...props} style={{ backgroundColor: props.style?.backgroundColor }}>
                {option.name}
              </MenuItem>
            )}
          />
          <InfoToolTip text="The list of technologies used to implement and deploy the project. At least one technology must be selected." />
        </FlexContainer>
        <FlexContainer>
          <Autocomplete
            options={modifyIndustryOptions().sort((a, b) => a.Office.localeCompare(b.Office))}
            multiple
            getOptionLabel={option => option['Industry Title']}
            value={industries}
            isOptionEqualToValue={(option, value) => option['SIC Code'] === value['SIC Code']}
            groupBy={option => option.Office}
            disabled={!isOwner}
            onChange={(_e: any, newValue: Industry[] | null) => handleChange('industries', newValue as Industry[])}
            sx={{ flex: 1, mr: 1 }}
            renderInput={params => <TextField {...params} label="Industries" />}
            renderOption={(props, option) => (
              <MenuItem {...props} style={{ backgroundColor: props.style?.backgroundColor }}>
                {option['Industry Title']}
              </MenuItem>
            )}
          />
          <InfoToolTip text="An optional list of industries which the customer operates in." />
        </FlexContainer>
        <Typography style={{ marginLeft: '10px' }}>Links</Typography>
        <FlexContainer>
          <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'space-between' }}>
            <TextField
              name="urlTitle"
              label="Title"
              value={urlTitle}
              disabled={!isOwner}
              onChange={e => handleChange('urlTitle', e.target.value)}
              error={urlTitleError}
              helperText={urlTitleError && 'URL title cannot be empty'}
              sx={{ width: 'calc(50% - 4px)' }}
            />
            <TextField
              name="urlCategory"
              label="Category"
              value={urlCategory}
              disabled={!isOwner}
              onChange={e => handleChange('urlCategory', e.target.value)}
              error={urlTitleError}
              sx={{ width: 'calc(50% - 4px)' }}
            />
            <TextField
              name="url"
              label="Url"
              value={url}
              disabled={!isOwner}
              onChange={e => handleChange('url', e.target.value)}
              error={!!validationErrors.url}
              helperText={validationErrors.url}
              sx={{ width: 'calc(50% - 4px)' }}
            />

            <Button
              disabled={!isOwner || !!validationErrors.url}
              variant="outlined"
              onClick={handleAddUrl}
              style={buttonStyle}
            >
              <AddIcon />
            </Button>
            <FlexContainer>
              <InfoToolTip text="An optional list of additional links related to the project - e.g., the customer’s website, the development and production environments URLs." />
            </FlexContainer>
          </Stack>
        </FlexContainer>
        <ProjectInfoUrlTable urls={urls} deleteUrl={deleteUrl} isOwner={isOwner} />
      </Stack>
    </Box>
  );
};
