import React, { useState, useEffect, useContext } from 'react';
import { AlertSnackbar, AppContext } from '@softwareone/plugin-project-management-react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Box, Button, Paper } from '@material-ui/core';
import { useApi, githubAuthApiRef } from '@backstage/core-plugin-api';
import Stack from '@mui/material/Stack';
import { SystemEntity, Technology, Industry, Link } from '@softwareone/plugin-project-management-common';
import { Progress } from '@backstage/core-components';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { checkUserOwnership, extractGroupAdminInfo, handleSetUrls, UserObject, successMessage } from '../utils/utils';
import { ProjectInfoItem } from '@softwareone/plugin-project-management-react/src/components/features/projectInfo/ProjectInfoItem';
import { useAdditionalInfo } from '../hook/useAdditionalInfo';
import { UpdateProject } from '@softwareone/plugin-project-management-react/src/services/types';
import {
  ServiceLine,
  ProjectStage,
} from '@softwareone/plugin-project-management-common/src/types/backstage/catalog/system-entity/service-line';

const extractCustomLinks = (links: Link[] | undefined): Link[] => {
  if (!links) return [];
  return links.filter(link => link.type === 'custom');
};

export const ProjectInfoCard = () => {
  const { entity } = useEntity();
  const { name: currentSystemName } = entity.metadata;
  const {
    description,
    title,
    type,
    customer,
    oneSalesId,
    links,
    erpProjectNumber: erpId,
    psaId,
    technologies: projectTechnologies,
    industries: projectIndustries,
    creatorActiveDirectoryId,
  } = entity.metadata as SystemEntity;
  const projectKey = currentSystemName;
  const [projectType, setProjectType] = useState<string>(type);
  const [oneSalesID, setOneSalesID] = useState<string>(oneSalesId);
  const [psaProjectId, setPsaProjectId] = useState<string>(psaId);
  const [erpProjectNumber, setErpProjectNumber] = useState<string>(erpId ?? '');
  const [projectCustomer, setProjectCustomer] = useState<string>(customer);
  const [projectTitle, setProjectTitle] = useState<string>(title);
  const [url, setUrl] = useState<string>('');
  const [urlTitle, setUrlTitle] = useState<string>('');
  const [urlCategory, setUrlCategory] = useState<string>('');
  const [urls, setUrls] = useState<Link[]>(extractCustomLinks(links));
  const [urlTitleError, setUrlTitleError] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(!!projectTechnologies);
  const [projectDescription, setProjectDescription] = useState<string>(description);
  const [projectKeyState, setProjectKeyState] = useState<string>('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<'success' | 'error'>('success');
  const [open, setOpen] = useState(false);
  const [isOwner, setIsOwner] = useState<boolean | undefined>(false);
  const [technologies, setTechnologies] = useState<Technology[]>(projectTechnologies || []);
  const [serviceLines, setServiceLines] = useState<ServiceLine[]>([]);
  const [selectedServiceLine, setSelectedServiceLine] = useState<ServiceLine | null>(null);
  const [selectedValueServiceLineStage, setSelectedValueServiceLineStage] = useState<ProjectStage | null>(null);
  const [industries, setIndustries] = useState<Industry[]>(projectIndustries || []);
  const gitHubApi = useApi(githubAuthApiRef);
  const { backstageIdentity, profile } = useUserProfile();
  const formattedProfile = { id: '', authProvider: '', ...profile };
  const {
    services: { projectService },
  } = useContext(AppContext);
  const { value: additionalInfo, loading: additionalInfoLoading } = useAdditionalInfo();
  const handleUrlAddition = () => {
    const success = handleSetUrls(url, urlTitle, urlCategory, setUrls, setUrlTitleError);
    if (success) {
      setUrl('');
      setUrlTitle('');
      setUrlCategory('');
    }
  };

  const deleteUrl = (indexToDelete: number) => {
    const updatedUrls = [...urls];
    updatedUrls.splice(indexToDelete, 1);
    setUrls(updatedUrls);
  };

  useEffect(() => {
    const removeCustomer = projectKey.split('-');
    if (removeCustomer.length > 1) {
      setProjectKeyState(removeCustomer[1]);
    } else {
      setProjectKeyState(projectKey);
    }
  }, [projectKey]);

  useEffect(() => {
    if (selectedServiceLine) {
      if (selectedServiceLine.stages.length > 0) {
        setSelectedValueServiceLineStage(selectedServiceLine.stages[0]);
      } else {
        setSelectedValueServiceLineStage(null);
      }
    }
  }, [selectedServiceLine]);

  useEffect(() => {
    const fetchServiceLines = async () => {
      try {
        const data = await projectService.getServiceLines();
        const serviceLinesWithEmptyOption = [{ id: '', name: '\u00A0', stages: [] }, ...data];
        setServiceLines(serviceLinesWithEmptyOption);

        const initialServiceLine =
          data.find((serviceLine: ServiceLine) => serviceLine.id === entity.metadata.serviceLineId) || null;
        setSelectedServiceLine(initialServiceLine);

        const initialProjectStage =
          data
            .flatMap((serviceLine: ServiceLine) => serviceLine.stages)
            .find((stage: ProjectStage) => stage.id === entity.metadata.projectStageId) || null;
        setSelectedValueServiceLineStage(initialProjectStage);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchServiceLines();
  }, []);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitLoading(true);
    const requestBody: UpdateProject = {
      projectDescription,
      oneSalesId: oneSalesID,
      psaProjectId,
      erpProjectNumber,
      projectTitle,
      projectKey,
      technologies: technologies.map(tech => tech.id),
      industries: industries.map(industry => industry['SIC Code']),
      links: urls,
      serviceLineId: selectedServiceLine?.id,
      projectStageId: selectedValueServiceLineStage?.id,
    };
    try {
      await projectService.updateProject(requestBody);
      setMessage(`Project Info ${successMessage}`);
      setStatus('success');
      setSubmitLoading(false);
      setOpen(true);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error occurred';
      setMessage(errorMessage);
      setStatus('error');
      setSubmitLoading(false);
      setOpen(true);
    }
  };

  const userObject: UserObject = {
    type: backstageIdentity?.type || '',
    userEntityRef: backstageIdentity?.userEntityRef || '',
    ownershipEntityRefs: backstageIdentity?.ownershipEntityRefs || [],
  };

  const hasGithubAdmins: boolean = extractGroupAdminInfo(userObject);

  useEffect(() => {
    if (!profile) return;
    const isUserOwner = async () => {
      await checkUserOwnership(
        await gitHubApi.getProfile(),
        projectService,
        currentSystemName,
        formattedProfile,
        hasGithubAdmins,
        setIsOwner,
        creatorActiveDirectoryId
      );
    };
    isUserOwner();
  }, [hasGithubAdmins, profile]);

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  if (submitLoading || additionalInfoLoading) {
    return <Progress />;
  }

  type ChangeData = {
    projectType: string;
    oneSalesID: string;
    psaProjectId: string;
    projectCustomer: string;
    projectTitle: string;
    projectKeyState: string;
    projectDescription: string;
    erpProjectNumber: string;
    industries: Industry[];
    technologies: Technology[];
    url: string;
    urlTitle: string;
    urlCategory: string;
    serviceLines: ServiceLine[];
    selectedValueServiceLineStage: ProjectStage | null;
  };

  const handleChange = (e: ChangeData) => {
    setProjectType(e.projectType);
    setOneSalesID(e.oneSalesID);
    setPsaProjectId(e.psaProjectId);
    setProjectCustomer(e.projectCustomer);
    setProjectTitle(e.projectTitle);
    setProjectKeyState(e.projectKeyState);
    setProjectDescription(e.projectDescription);
    setIndustries(e.industries);
    setTechnologies(e.technologies);
    setUrl(e.url);
    setUrlTitle(e.urlTitle);
    setUrlCategory(e.urlCategory);
    setErpProjectNumber(e.erpProjectNumber);
    setServiceLines(e.serviceLines);
  };

  const handleValidityChange = (e: boolean) => {
    setIsValid(e);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>
      <Paper>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ m: 2 }}>
            <ProjectInfoItem
              isOwner={isOwner}
              onChange={handleChange}
              projectType={projectType}
              erpProjectNumber={erpProjectNumber}
              oneSalesID={oneSalesID}
              psaProjectId={psaProjectId}
              projectCustomer={projectCustomer}
              projectTitle={projectTitle}
              technologyOptions={additionalInfo.technologies}
              industryOptions={additionalInfo.industries}
              industries={industries}
              technologies={technologies}
              projectKeyState={projectKeyState}
              projectDescription={projectDescription}
              onValidityChange={handleValidityChange}
              url={url}
              urlTitle={urlTitle}
              urlCategory={urlCategory}
              handleAddUrl={handleUrlAddition}
              deleteUrl={deleteUrl}
              urls={urls}
              urlTitleError={urlTitleError}
              serviceLines={serviceLines}
              selectedServiceLine={selectedServiceLine}
              setSelectedServiceLine={setSelectedServiceLine}
              setSelectedValueServiceLineStage={setSelectedValueServiceLineStage}
              selectedValueServiceLineStage={selectedValueServiceLineStage}
            />
            <Box sx={{ pr: '30px', pl: '30px' }}>
              <Button variant="outlined" fullWidth type="submit" disabled={!isValid || !isOwner}>
                Save
              </Button>
            </Box>
          </Stack>
        </form>
        <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} />
      </Paper>
    </Box>
  );
};
