import { HttpService } from './http-service';
import { EnvironmentFrontEnd } from '../../../../packages/app/src/components/card/EnvironmentsCard';

export class ProjectEnvironmentService {
  constructor(private readonly httpService: HttpService) {}

  public async updateEnvironments(
    projectKey: string,
    environments: EnvironmentFrontEnd[],
    nonTeamMembersCanViewEnvironments: boolean
  ): Promise<void> {
    const requestBody = { environments, nonTeamMembersCanViewEnvironments };
    const res = await this.httpService.put(`/projects/${projectKey}/environments`, requestBody);

    if (!res.ok) {
      if (res.status === 502 || res.status === 503) {
        throw new Error('Server error');
      }
      const result = await res.json();
      throw new Error(result.message);
    }
  }
}
