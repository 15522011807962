import React from 'react';
import { CreateProps, ForkProps } from './GitHubProps';
import { ForkItem } from './ForkItem';
import { CreateItem } from './CreateItem';

export const GitHubItem = (props: CreateProps | ForkProps) => {
  if (props.propType === 'Fork') {
    return <ForkItem {...props} />;
  }
  return <CreateItem {...props} />;
};
