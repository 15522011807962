import { HttpService } from './http-service';
import { CreateConfluenceSpace, UpdateConfluenceSpaceTeams } from './types';

export class ConfluenceService {
  constructor(private readonly httpService: HttpService) {}

  public async createConfluenceSpace(createConfluenceSpace: CreateConfluenceSpace) {
    const { projectKey, ...rest } = createConfluenceSpace;
    const res = await this.httpService.post(`/projects/${projectKey}/confluence/spaces`, rest);
    if (!res.ok) {
      throw new Error(`Error! Status: ${res.status}`);
    }
  }

  public async getConfluenceOrgUsers() {
    const res = await this.httpService.get('/projects/org/confluence/usernames');
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.message);
    }
    return data;
  }
  public async getTeamMembers(projectKey: string) {
    const res = await this.httpService.get(`/projects/${projectKey}/confluence/teams`);
    return res.json();
  }
  public async updateTeamMembers(updateConfluenceSpaceTeams: UpdateConfluenceSpaceTeams) {
    const { projectKey, ...rest } = updateConfluenceSpaceTeams;
    const res = await this.httpService.put(`/projects/${projectKey}/confluence/teams`, rest);
    if (res.status !== 204) {
      throw new Error(`Error! Status: ${res.status}`);
    }
  }
  public async checkConfluenceSpaceKey(confluenceSpaceKey: string) {
    const res = await this.httpService.post('/projects/prevalidation/confluence-space-key', { confluenceSpaceKey });
    if (res.status === 400 || res.status === 409) {
      const result = await res.json();
      throw new Error(result.message);
    }
  }
}
