import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ChangeDataProjectInfoType } from '../CreateProjectInfo';
import { StyledRow, StyledField, StyledDataField } from '../../styled/styles';
import {
  ProjectStage,
  ServiceLine,
} from '@softwareone/plugin-project-management-common/src/types/backstage/catalog/system-entity/service-line';

interface TableProjectInfoProps {
  createProjectInfoForm: ChangeDataProjectInfoType;
  urls: { url: string; title: string }[];
  selectedServiceLine: ServiceLine | null;
  selectedValueServiceLineStage: ProjectStage | null;
}

export const TableProjectInfo: React.FC<TableProjectInfoProps> = projectInfoData => {
  const createDataProjectInfo = (field: string, dataField: string | string[] | number[]) => {
    return { field, dataField };
  };
  const urlsLinks = projectInfoData.urls.map((x, index) => `[ ${x.title}: ${projectInfoData.urls[index]?.url || ''} ]`);
  const rowsProjectInfo = [
    createDataProjectInfo('Project Type', projectInfoData.createProjectInfoForm.projectType),
    createDataProjectInfo('OneSales ID', projectInfoData.createProjectInfoForm.oneSalesID),
    createDataProjectInfo('PSA Project ID', projectInfoData.createProjectInfoForm.psaProjectId),
    createDataProjectInfo('ERP Project Number', projectInfoData.createProjectInfoForm.erpProjectNumber),
    createDataProjectInfo('Customer', projectInfoData.createProjectInfoForm.projectCustomer),
    createDataProjectInfo('Project Title', projectInfoData.createProjectInfoForm.projectTitle),
    createDataProjectInfo('Project Key', projectInfoData.createProjectInfoForm.projectKeyState),
    createDataProjectInfo('Project Description', projectInfoData.createProjectInfoForm.projectDescription),
    createDataProjectInfo('Service Line', projectInfoData.selectedServiceLine?.name ?? ''),
    createDataProjectInfo('Stage', projectInfoData.selectedValueServiceLineStage?.name ?? ''),
    createDataProjectInfo(
      'Technologies',
      projectInfoData.createProjectInfoForm.technologies.map(tech => tech.name)
    ),
    createDataProjectInfo(
      'Industries',
      projectInfoData.createProjectInfoForm.industries.map(industry => industry['Industry Title'])
    ),
    createDataProjectInfo('Links', urlsLinks),
  ];

  return (
    <Box>
      <Typography variant="h6">Project Info </Typography>
      {rowsProjectInfo.map(row => (
        <StyledRow key={row.field}>
          <StyledField>{row.field}</StyledField>
          <StyledDataField>{Array.isArray(row.dataField) ? row.dataField.join(', ') : row.dataField}</StyledDataField>
        </StyledRow>
      ))}
    </Box>
  );
};
