import React, { useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import { MultiValueInput } from '../../fields/multiValueInputField/MultiValueInput';
import { Box, Checkbox, FormControlLabel, Button } from '@mui/material';
import * as _ from 'lodash';
import { FormGroup } from '@material-ui/core';
import { AppContext } from '../../../context';
import { FlexContainer, InfoToolTip } from '../../infoToolTip/InfoToolTip';

type ConfluenceItemProps = {
  confluenceSpaceKey?: string;
  confluenceSpaceName?: string;
  confluenceAdministrators: string[];
  confluenceContributors: string[];
  confluenceCustomers: string[];
  userList: string[];
  withSharedJiraProject?: boolean;
  onValidityChange?: (e: boolean) => void;
  enableSpaceInfo?: boolean;
  onChange: (e: SelectedUsers) => void;
  isDisabled: boolean;
  confluenceVisibilityForSwoEmployees?: boolean;
  getTeamMembersForConfluence?: () => void;
};

type SelectedUsers = Omit<
  ConfluenceItemProps,
  | 'userList'
  | 'onValidityChange'
  | 'enableSpaceInfo'
  | 'onChange'
  | 'confluenceSpaceName'
  | 'isDisabled'
  | 'withSharedJiraProject'
>;
type Key =
  | 'confluenceAdministrators'
  | 'confluenceCustomers'
  | 'confluenceContributors'
  | 'confluenceVisibilityForSwoEmployees';

export const ConfluenceItem = ({
  userList,
  confluenceSpaceKey,
  confluenceSpaceName,
  confluenceAdministrators,
  confluenceContributors,
  confluenceCustomers,
  confluenceVisibilityForSwoEmployees,
  onValidityChange,
  onChange,
  withSharedJiraProject,
  isDisabled = false,
  enableSpaceInfo = true,
  getTeamMembersForConfluence,
}: ConfluenceItemProps) => {
  const [errorMessage, setErrorMessage] = useState('');

  const {
    services: { confluenceService },
  } = useContext(AppContext);

  const checkSpaceKey = async (confluenceSpaceKeyParam: string) => {
    if (onValidityChange) {
      try {
        await confluenceService.checkConfluenceSpaceKey(confluenceSpaceKeyParam);
        setErrorMessage('');
        onValidityChange(true);
      } catch (err) {
        if (err instanceof Error) {
          setErrorMessage(err.message);
        }
        onValidityChange(false);
      }
    }
  };

  const debouncedCheckSpaceKey = _.debounce(checkSpaceKey, 300);

  const handleSpaceKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      confluenceAdministrators: confluenceAdministrators,
      confluenceContributors: confluenceContributors,
      confluenceCustomers: confluenceCustomers,
      confluenceVisibilityForSwoEmployees,
      confluenceSpaceKey: e.target.value,
    });
    debouncedCheckSpaceKey(e.target.value);
  };

  const handleValueChange = (key: Key, value: string[] | string | boolean) => {
    const usersToSelect = {
      confluenceAdministrators: confluenceAdministrators,
      confluenceContributors: confluenceContributors,
      confluenceCustomers: confluenceCustomers,
      confluenceSpaceKey,
      confluenceVisibilityForSwoEmployees,
      [key]: value,
    };
    onChange(usersToSelect);
  };
  return (
    <>
      {enableSpaceInfo && (
        <>
          <Box sx={{ display: 'grid', gap: 3, mx: 2, mt: 4 }}>
            <FlexContainer>
              <TextField
                value={confluenceSpaceName}
                fullWidth
                name="confluenceSpaceName"
                label="Confluence Space Name"
                variant="outlined"
                disabled
              />
              <Box sx={{ ml: 1 }}>
                <InfoToolTip text="The name of the Confluence space - generated automatically from the customer name and the project title." />
              </Box>
            </FlexContainer>
            <FlexContainer>
              <TextField
                variant="standard"
                id="filled-error"
                fullWidth
                name="confluenceSpaceKey"
                label="Confluence Space Key"
                value={confluenceSpaceKey}
                required
                disabled={isDisabled || withSharedJiraProject}
                error={isDisabled ? false : !!errorMessage}
                helperText={isDisabled ? '' : errorMessage}
                onChange={handleSpaceKeyChange}
              />
              <Box sx={{ mt: 2, ml: 1 }}>
                <InfoToolTip
                  text={
                    'The key which will uniquely identify the space in Confluence.\nValid format <two-letter ISO country code><at least 1 and at most 8 letters and digits>.'
                  }
                />
              </Box>
            </FlexContainer>
          </Box>
        </>
      )}
      <FlexContainer>
        <MultiValueInput
          label="Confluence Space Administrators"
          data={userList}
          value={confluenceAdministrators}
          placeholder="Select Users"
          isDisabled={isDisabled}
          onValueChange={e => handleValueChange('confluenceAdministrators', e)}
        />
        <Box sx={{ mt: 8, mr: 2 }}>
          <InfoToolTip text="The Confluence users who can configure and administer the Confluence Space." />
        </Box>
      </FlexContainer>
      <FlexContainer>
        <MultiValueInput
          label="Confluence Space Contributors"
          data={userList}
          value={confluenceContributors}
          placeholder="Select Users"
          isDisabled={isDisabled}
          onValueChange={e => handleValueChange('confluenceContributors', e)}
        />
        <Box sx={{ mt: 8, mr: 2 }}>
          <InfoToolTip text="The Confluence users who can create and edit pages in the Confluence Space." />
        </Box>
      </FlexContainer>
      <FlexContainer>
        <MultiValueInput
          label="Confluence Space Customers"
          data={userList}
          value={confluenceCustomers}
          placeholder="Select Users"
          isDisabled={isDisabled}
          onValueChange={e => handleValueChange('confluenceCustomers', e)}
        />
        <Box sx={{ mt: 8, mr: 2 }}>
          <InfoToolTip text="The Confluence users who can only view pages in the Confluence Space." />
        </Box>
      </FlexContainer>
      <FlexContainer>
        <Box sx={{ m: 2 }}>
          <Button variant="outlined" disabled={isDisabled} onClick={getTeamMembersForConfluence}>
            Copy from Team
          </Button>
        </Box>
        <InfoToolTip text="Use this button to automatically populate the administrators, contributors and customers from the owners, contributors and readers teams of the project respectively. The already selected users in these fields will be removed" />
      </FlexContainer>
      <FlexContainer>
        <FormGroup>
          <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              control={
                <Checkbox
                  inputProps={{ 'aria-label': 'controlled' }}
                  disabled={isDisabled}
                  checked={confluenceVisibilityForSwoEmployees}
                  onChange={e => handleValueChange('confluenceVisibilityForSwoEmployees', e.target.checked)}
                />
              }
              label="Visible to all swo employees"
            />
            <InfoToolTip text="If enabled, all SoftwareOne Confluence users will be allowed to view pages in the Confluence Space." />
          </Box>
        </FormGroup>
      </FlexContainer>
    </>
  );
};
