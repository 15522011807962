import React from 'react';
import { Box, Typography, Button, Link } from '@material-ui/core';
import { Content, ContentHeader, PageWithHeader } from '@backstage/core-components';
import { EntityListProvider } from '@backstage/plugin-catalog-react';

export function BugsFeaturesPage() {
  return (
    <PageWithHeader title="Bugs / Features" themeId="home">
      <Content>
        <ContentHeader title="" />
        <EntityListProvider>
          <Box sx={{ m: 5, width: 'auto', display: 'inline-flex' }}>
            <Box>
              <Box sx={{ m: 2 }}>
                <Typography>
                  You can report any issues you have found in the SoftwareOne DevOps Platform or request features you
                  need in the{' '}
                  <Link
                    href="https://softwareone-services.atlassian.net/browse/SWODO"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#757575' }}
                  >
                    Jira project
                  </Link>{' '}
                  of the platform
                </Typography>
              </Box>
              <Box sx={{ m: 2 }}>
                <Button
                  href="https://softwareone-services.atlassian.net/browse/SWODO"
                  target="_blank"
                  rel="noopener noreferrer"
                  size="medium"
                  variant="outlined"
                >
                  Go to Jira project
                </Button>
              </Box>
            </Box>
          </Box>
        </EntityListProvider>
      </Content>
    </PageWithHeader>
  );
}
