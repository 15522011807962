import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './props/FormInputProps';

type SelectInputProps = FormInputProps & {
  options: { label: string; value: string }[];
  onUpdate?: (input: string) => void;
};

export const SelectFormInput = ({ name, control, label, options, disabled = false, onUpdate }: SelectInputProps) => {
  const generateOptions = () => {
    return options.map((option: any) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl sx={{ flex: 1 }}>
      <InputLabel>{label}</InputLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              PaperProps: {
                style: {
                  maxHeight: 250,
                },
              },
            }}
            onChange={e => {
              onChange(e);
              if (onUpdate) {
                onUpdate(e.target.value);
              }
            }}
            value={value}
            disabled={disabled}
          >
            {generateOptions()}
          </Select>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};
