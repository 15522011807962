import React, { useEffect, useState } from 'react';
import { Box, FormControl } from '@mui/material';
import Select, { MultiValue, SingleValue } from 'react-select';

type organizationUserOptionEntry = {
  value: string;
  label: string;
  data?: { [key: string]: any };
};

interface MultiValueInputProps {
  label: string;
  data: any[];
  mapperFn?: (input: any) => {
    value: string;
    label: string;
    data?: { [key: string]: any };
  };
  minDataCount?: number;
  filterOptions?: (candidate: { label: string; value: string; data: any }, input: string) => boolean;
  value: any;
  setMembers: React.Dispatch<React.SetStateAction<string[]>>;
  isDisabled?: boolean;
  handleInputChange?: (inputValue: string) => void;
  isMulti?: boolean;
  placeholder?: string;
}

export const MultiValueInputField = (props: MultiValueInputProps) => {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<organizationUserOptionEntry>>([]);
  const initialTeamMembersMultiValue: organizationUserOptionEntry[] = [];

  props.data.forEach(entry => {
    initialTeamMembersMultiValue.push({ value: entry, label: entry });
  });

  useEffect(() => {
    setSelectedOptions(initialTeamMembersMultiValue);
    props.setMembers(props.data);
  }, []);

  const options: organizationUserOptionEntry[] = [];
  if (Array.isArray(props.value) && !props.mapperFn) {
    (props.value as string[]).forEach(entry => {
      options.push({ value: entry, label: entry });
    });
  } else if (Array.isArray(props.value) && props.mapperFn) {
    props.value.map(props.mapperFn).forEach(entry => {
      options.push({
        value: entry.value,
        label: entry.label,
        data: entry.data,
      });
    });
  }

  const optionsToSelect = options.filter(
    opt => !(selectedOptions as organizationUserOptionEntry[]).some(item => item.value === opt.value)
  );
  const handleInputChange = (newValue: string): void | string => {
    if (props.isMulti === undefined || props.isMulti) {
      const inputArr = newValue.split(';');
      const selectableValues: organizationUserOptionEntry[] = [];
      const inputToChange = inputArr.filter(input => {
        const matchedValue = optionsToSelect.find(
          opt =>
            opt.data?.email?.toLowerCase() === input.trim().toLowerCase() ||
            opt.label.toLowerCase() === input.trim().toLowerCase()
        );
        if (matchedValue) {
          const value = matchedValue.data?.email ? { ...matchedValue, label: matchedValue.value } : matchedValue;
          selectableValues.push(value);
          return false;
        }
        return true;
      });
      if (selectableValues.length > 0) {
        const selectedValues: organizationUserOptionEntry[] = [...selectedOptions, ...selectableValues];
        setSelectedOptions(selectedValues);
        props.setMembers(selectedValues.map(option => option.value));
      }

      return inputToChange.join(';');
    }
  };

  function handleSelect(eventData: MultiValue<organizationUserOptionEntry> | SingleValue<organizationUserOptionEntry>) {
    const formattedData: string[] = [];
    if (Array.isArray(eventData)) {
      eventData.forEach(element => {
        element.label = element.value;
        formattedData.push(element.value);
      });
      setSelectedOptions(eventData);
    } else {
      (eventData as any).label = (eventData as any).value;
      formattedData.push((eventData as any).value);
      setSelectedOptions([eventData as MultiValueInputProps]);
    }
    props.setMembers(formattedData);
  }

  return (
    <FormControl sx={{ flex: 1 }}>
      <Box sx={{ m: 2 }}>
        <div className="dropdown-container">
          <h2>{props.label}</h2>
          <Select
            options={options}
            isDisabled={props.isDisabled}
            placeholder={props.placeholder}
            filterOption={props.filterOptions}
            value={selectedOptions}
            onChange={handleSelect}
            onInputChange={props.handleInputChange || handleInputChange}
            isSearchable
            isMulti={props.isMulti !== undefined ? props.isMulti : true}
          />
        </div>
      </Box>
    </FormControl>
  );
};
