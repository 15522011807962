import { HttpService } from './http-service';

export class MetricsService {
  constructor(private readonly httpService: HttpService) {}

  public async getLeadTimeForChanges(projectKey: string, componentKey: string) {
    const res = await this.httpService.get(
      `/projects/${projectKey}/components/${componentKey}/metrics/lead-time-for-changes`
    );
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.message);
    }
    return data;
  }

  public async getDeploymentFrequency(projectKey: string, componentKey: string) {
    const res = await this.httpService.get(
      `/projects/${projectKey}/components/${componentKey}/metrics/deployment-frequency`
    );
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.message);
    }
    return data;
  }
}
