import { QueryEntitiesInitialRequest } from '@backstage/catalog-client';
import { parseEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { compact, isEqual } from 'lodash';
import { useMemo, useRef } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { catalogApiRef, useEntityList, useStarredEntities } from '@backstage/plugin-catalog-react';
import { EntityUserFilter } from '../filter';
import { reduceCatalogFilters } from '../components/utils';

export const useStarredEntitiesCount = () => {
  const catalogApi = useApi(catalogApiRef);
  const { filters } = useEntityList();
  const { starredEntities } = useStarredEntities();
  const prevRequest = useRef<QueryEntitiesInitialRequest>();
  const request = useMemo(() => {
    // ToDo fixme
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { user, ...allFilters } = filters;
    const compacted = compact(Object.values(allFilters));
    const catalogFilters = reduceCatalogFilters(compacted);
    const facet = 'metadata.name';
    const specTypeFacet = 'spec.type';
    const lifefcycleFacet = 'spec.lifecycle';
    const newRequest: QueryEntitiesInitialRequest = {
      ...catalogFilters,
      filter: {
        kind: filters.kind?.value || '',
        [specTypeFacet]: filters.type?.value || [],
        [lifefcycleFacet]: filters.lifecycles?.values || [],
        /**
         * here we are filtering entities by `name`. Given this filter,
         * the response might contain more entities than expected, in case multiple entities
         * of different kind or namespace share the same name. Those extra entities are filtered out
         * client side by `EntityUserFilter`, so they won't be visible to the user.
         */
        [facet]: Array.from(starredEntities).map(e => parseEntityRef(e).name),
      },
      /**
       * limit is set to a high value as we are not expecting many starred entities
       */
      limit: 1000,
    };
    if (isEqual(newRequest, prevRequest.current)) {
      return prevRequest.current;
    }
    prevRequest.current = newRequest;

    return newRequest;
  }, [filters, starredEntities]);

  const { value: count, loading } = useAsync(async () => {
    if (!starredEntities.size) {
      return 0;
    }

    /**
     * given a list of starred entity refs and some filters coming from CatalogPage,
     * it reduces the list of starred entities, to a list of entities that matches the
     * provided filters. It won't be possible to getEntitiesByRefs
     * as the method doesn't accept any filter.
     */
    const response = await catalogApi.queryEntities(request);

    return response.items
      .map(e =>
        stringifyEntityRef({
          kind: e.kind,
          namespace: e.metadata.namespace,
          name: e.metadata.name,
        })
      )
      .filter(e => starredEntities.has(e)).length;
  }, [request, starredEntities]);

  const filter = useMemo(() => EntityUserFilter.starred(Array.from(starredEntities)), [starredEntities]);

  return { count, loading, filter };
};
