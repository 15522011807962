import { Entity, DEFAULT_NAMESPACE, RELATION_OWNED_BY } from '@backstage/catalog-model';
import {
  Content,
  Header,
  HeaderLabel,
  Link,
  Page,
  Progress,
  RoutedTabs,
  WarningPanel,
} from '@backstage/core-components';
import {
  attachComponentData,
  githubAuthApiRef,
  IconComponent,
  useApi,
  useElementFilter,
  useRouteRefParams,
} from '@backstage/core-plugin-api';
import {
  EntityRefLinks,
  entityRouteRef,
  FavoriteEntity,
  getEntityRelations,
  InspectEntityDialog,
  useAsyncEntity,
} from '@backstage/plugin-catalog-react';
import Box from '@material-ui/core/Box';
import { TabProps } from '@material-ui/core/Tab';
import Alert from '@material-ui/lab/Alert';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EntityContextMenu } from './menu/EntityContextMenu';
import { EntityDisplayName } from './entity/EntityDisplayName';
import { AppContext } from '@softwareone/plugin-project-management-react';

/** @public */
export type EntityLayoutRouteProps = {
  path: string;
  title: string;
  children: React.JSX.Element;
  if?: (entity: Entity) => boolean;
  tabProps?: TabProps<React.ElementType, { component?: React.ElementType }>;
};

const dataKey = 'plugin.catalog.entityLayoutRoute';

const Route: (props: EntityLayoutRouteProps) => null = () => null;
attachComponentData(Route, dataKey, true);
attachComponentData(Route, 'core.gatherMountPoints', true); // This causes all mount points that are discovered within this route to use the path of the route itself

function EntityLayoutTitle(props: { title: string; entity: Entity | undefined }) {
  const { entity, title } = props;
  return (
    <Box display="inline-flex" alignItems="center" height="1em" maxWidth="100%">
      <Box component="span" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
        {entity ? <EntityDisplayName entityRef={entity} hideIcon /> : title}
      </Box>
      {entity && <FavoriteEntity entity={entity} />}
    </Box>
  );
}

function headerProps(
  paramKind: string | undefined,
  paramNamespace: string | undefined,
  paramName: string | undefined,
  entity: Entity | undefined
): { headerTitle: string; headerType: string } {
  const kind = paramKind ?? entity?.kind ?? '';
  const namespace = paramNamespace ?? entity?.metadata.namespace ?? '';
  const name = entity?.metadata.title ?? paramName ?? entity?.metadata.name ?? '';
  return {
    headerTitle: `${name}${namespace && namespace !== DEFAULT_NAMESPACE ? ` in ${namespace}` : ''}`,
    headerType: (() => {
      let t = kind.toLocaleLowerCase('en-US');
      if (entity && entity.spec && 'type' in entity.spec) {
        t += ' — ';
        t += (entity.spec as { type: string }).type.toLocaleLowerCase('en-US');
      }
      return t;
    })(),
  };
}

function EntityLabels(props: { entity: Entity }) {
  const { entity } = props;
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

  return (
    <>
      {ownedByRelations.length > 0 && (
        <HeaderLabel
          label="Owner"
          value={<EntityRefLinks entityRefs={ownedByRelations} defaultKind="Group" color="inherit" />}
        />
      )}
      {entity.spec?.lifecycle && <HeaderLabel label="Lifecycle" value={entity.spec.lifecycle?.toString()} />}
    </>
  );
}

interface ExtraContextMenuItem {
  title: string;
  Icon: IconComponent;
  onClick: () => void;
}

type VisibleType = 'visible' | 'hidden' | 'disable';

interface EntityContextMenuOptions {
  disableUnregister: boolean | VisibleType;
}

/** @public */
export interface EntityLayoutProps {
  UNSTABLE_extraContextMenuItems?: ExtraContextMenuItem[];
  UNSTABLE_contextMenuOptions?: EntityContextMenuOptions;
  children?: React.ReactNode;
  NotFoundComponent?: React.ReactNode;
}

export const CustomEntityLayout = (props: EntityLayoutProps) => {
  const {
    services: { projectService },
  } = useContext(AppContext);
  const { UNSTABLE_extraContextMenuItems, UNSTABLE_contextMenuOptions, children, NotFoundComponent } = props;
  const [teamMembersOfProject, setTeamMembersOfProject] = useState<Set<string>>(new Set());
  const [userName, setUserName] = useState<string>('');
  const gitHubIdentity = useApi(githubAuthApiRef);
  const { kind, namespace, name } = useRouteRefParams(entityRouteRef);
  const { entity, loading, error } = useAsyncEntity();
  const projectName = entity?.metadata.name ?? '';
  const location = useLocation();

  useEffect(() => {
    const updateUserName = async () => {
      const userProfile = await gitHubIdentity.getProfile();
      setUserName(userProfile?.displayName ?? '');
    };
    const getAllTeamMembersForProject = async () => {
      const teamMembers = await projectService.getProjectTeamInfo(projectName);
      const allMembers = [
        ...teamMembers.gitHubOwners,
        ...teamMembers.gitHubContributors,
        ...teamMembers.gitHubReaders,
      ].map(item => item.login);
      setTeamMembersOfProject(new Set(allMembers));
    };
    if (entity?.kind === 'System' && !entity.metadata.nonTeamMembersCanViewEnvironments) {
      Promise.all([updateUserName(), getAllTeamMembersForProject()]);
    }
  }, [entity]);
  const routes = useElementFilter(
    children,
    elements =>
      elements
        .selectByComponentData({
          key: dataKey,
          withStrictError: 'Child of EntityLayout must be an EntityLayout.Route',
        })
        .getElements<EntityLayoutRouteProps>()
        .flatMap(({ props: elementProps }) => {
          if (!entity) {
            return [];
          } else if (elementProps.if && !elementProps.if(entity)) {
            return [];
          }
          if (
            elementProps.title === 'Environments' &&
            !(entity.metadata.nonTeamMembersCanViewEnvironments === true || teamMembersOfProject.has(userName))
          ) {
            return [];
          }
          return [
            {
              path: elementProps.path,
              title: elementProps.title,
              children: elementProps.children,
              tabProps: elementProps.tabProps,
            },
          ];
        }),
    [entity, userName, teamMembersOfProject]
  );

  const { headerTitle, headerType } = headerProps(kind, namespace, name, entity);
  const [inspectionDialogOpen, setInspectionDialogOpen] = useState(false);

  useEffect(() => {
    setInspectionDialogOpen(false);
  }, [location.pathname]);

  return (
    <Page themeId={entity?.spec?.type?.toString() ?? 'home'}>
      <Header
        title={<EntityLayoutTitle title={headerTitle} entity={entity!} />}
        pageTitleOverride={headerTitle}
        type={headerType}
      >
        {entity && (
          <>
            <EntityLabels entity={entity} />
            <EntityContextMenu
              UNSTABLE_extraContextMenuItems={UNSTABLE_extraContextMenuItems}
              UNSTABLE_contextMenuOptions={UNSTABLE_contextMenuOptions}
              onUnregisterEntity={() => undefined}
              onInspectEntity={() => setInspectionDialogOpen(true)}
            />
          </>
        )}
      </Header>

      {loading && <Progress />}

      {entity && <RoutedTabs routes={routes} />}

      {error && (
        <Content>
          <Alert severity="error">{error.toString()}</Alert>
        </Content>
      )}

      {!loading && !error && !entity && (
        <Content>
          {NotFoundComponent ? (
            NotFoundComponent
          ) : (
            <WarningPanel title="Entity not found">
              There is no {kind} with the requested{' '}
              <Link to="https://backstage.io/docs/features/software-catalog/references">kind, namespace, and name</Link>
              .
            </WarningPanel>
          )}
        </Content>
      )}

      <InspectEntityDialog
        open={inspectionDialogOpen}
        entity={entity!}
        onClose={() => setInspectionDialogOpen(false)}
      />
    </Page>
  );
};

CustomEntityLayout.Route = Route;
