import { ChangeDataTeam } from '../createProject/components/CreateTeam';
import { UserModel, Link, ConfluenceUserData } from '@softwareone/plugin-project-management-common';
import { ProjectService } from '@softwareone/plugin-project-management-react/src/services';
import React from 'react';
import { ProfileInfo } from '@backstage/core-plugin-api';
import { URL_PATTERN } from '@softwareone/plugin-project-management-common/src/types/regex-patterns/regexPatterns';

export type UserObject = {
  type: string;
  userEntityRef: string;
  ownershipEntityRefs: string[];
};

interface EmailUser {
  username: string;
  email: string;
}

interface JiraEmail {
  displayName: string;
  emailAddress: string;
}

interface PrevUsers {
  gitHubOwners: { login: string }[];
  gitHubContributors: { login: string }[];
  gitHubReaders: { login: string }[];
}
export interface SonarInstance {
  name: string;
}
interface SonarValues {
  extractSonarInstance: SonarInstance | undefined | string | null;
  extractProjectKeySonar: string | undefined;
}

type Links = React.Dispatch<React.SetStateAction<Link[]>>;

export const extractGroupAdminInfo = (userObject: UserObject): boolean => {
  const { ownershipEntityRefs } = userObject;

  const hasGithubAdminsGroup = ownershipEntityRefs.some(
    entityRef => entityRef.startsWith('group:default/') && entityRef.includes('githubadmins')
  );

  return hasGithubAdminsGroup;
};

export const extractGitHubSourcePeople = (userObject: UserObject): boolean => {
  const { ownershipEntityRefs } = userObject;

  const hasGitHubSourcePeople = ownershipEntityRefs.some(
    entityRef => entityRef.startsWith('group:default/') && entityRef.includes('qualitygatessourcepeople')
  );

  return hasGitHubSourcePeople;
};

export const addTeamMembers = (
  gitHubEmails: EmailUser[],
  emails: JiraEmail[] | ConfluenceUserData[],
  prevUsers: PrevUsers | undefined,
  setAdministrators: (admins: string[]) => void,
  setContributors: (contributors: string[]) => void,
  setCustomers: (customers: string[]) => void
) => {
  if (!prevUsers) return;
  const adminstratorsHold: string[] = [];
  const contributorsHold: string[] = [];
  const teamHold: string[] = [];

  prevUsers.gitHubOwners.forEach(owner => {
    gitHubEmails.forEach(emailUser => {
      if (emailUser.username === owner.login) {
        adminstratorsHold.push(emailUser.email);
      }
    });
  });

  prevUsers.gitHubContributors.forEach(contributor => {
    gitHubEmails.forEach(emailUser => {
      if (emailUser.username === contributor.login) {
        contributorsHold.push(emailUser.email);
      }
    });
  });

  prevUsers.gitHubReaders.forEach(member => {
    gitHubEmails.forEach(emailUser => {
      if (emailUser.username === member.login) {
        teamHold.push(emailUser.email);
      }
    });
  });

  const adminNames = [];
  const contributorNames = [];
  const teamNames = [];

  for (let i = 0; i < emails.length; i++) {
    let targetEmail;
    let targetName;

    const emailInfo = emails[i];
    if ('emailAddress' in emailInfo) {
      targetEmail = emailInfo.emailAddress ?? '';
      targetName = emailInfo.displayName;
    } else {
      targetEmail = emailInfo.email ?? '';
      targetName = emailInfo.publicName;
    }

    if (adminstratorsHold.includes(targetEmail)) {
      adminNames.push(targetName);
    }

    if (contributorsHold.includes(targetEmail)) {
      contributorNames.push(targetName);
    }

    if (teamHold.includes(targetEmail)) {
      teamNames.push(targetName);
    }
  }

  setAdministrators(Array.from(new Set([...adminNames])));
  setContributors(Array.from(new Set([...contributorNames])));
  setCustomers(Array.from(new Set([...teamNames])));
};

export const getJiraTeamMembers = (
  createTeamForm: ChangeDataTeam,
  teamMembersValue: { username: string; email: string }[],
  jiraTeamMembersValue: UserModel[]
) => {
  const updatedFilteredMembers: Record<string, string[]> = {
    owners: [],
    contributors: [],
    readers: [],
  };

  updatedFilteredMembers.owners = getUpdatedFilteredMembersEntryForJira(
    createTeamForm.gitHubOwners,
    teamMembersValue,
    jiraTeamMembersValue
  );
  updatedFilteredMembers.contributors = getUpdatedFilteredMembersEntryForJira(
    createTeamForm.gitHubContributors,
    teamMembersValue,
    jiraTeamMembersValue
  );
  updatedFilteredMembers.readers = getUpdatedFilteredMembersEntryForJira(
    createTeamForm.gitHubReaders,
    teamMembersValue,
    jiraTeamMembersValue
  );

  return updatedFilteredMembers;
};

export const getUpdatedFilteredMembersEntryForJira = (
  gitHubMembers: string[],
  teamMembersValue: { username: string; email: string }[],
  jiraTeamMembersValue: UserModel[]
) => {
  const updatedFilteredMembers: string[] = [];

  gitHubMembers.forEach(username => {
    const member = teamMembersValue.find(m => m.username === username);
    if (member) {
      const jiraMember = jiraTeamMembersValue.find(jiraMemberEmail => jiraMemberEmail.emailAddress === member.email);
      if (jiraMember) {
        updatedFilteredMembers.push(jiraMember.displayName || '');
      }
    }
  });

  return updatedFilteredMembers;
};

export const getUpdatedFilteredMembersEntryForConfluence = (
  gitHubMembers: string[],
  teamMembersValue: { username: string; email: string }[],
  confluenceTeamMembersValue: ConfluenceUserData[]
) => {
  const updatedFilteredMembers: string[] = [];

  gitHubMembers.forEach(username => {
    const member = teamMembersValue.find(m => m.username === username);
    if (member) {
      const confluenceMember = confluenceTeamMembersValue.find(
        confluenceMember => confluenceMember.email === member.email
      );
      if (confluenceMember) {
        updatedFilteredMembers.push(confluenceMember.publicName || '');
      }
    }
  });

  return updatedFilteredMembers;
};

export const getConfluenceTeamMembers = (
  createTeamForm: ChangeDataTeam,
  teamMembersValue: { username: string; email: string }[],
  confluenceMembersValue: ConfluenceUserData[]
) => {
  const updatedFilteredMembers: Record<string, string[]> = {
    owners: [],
    contributors: [],
    readers: [],
  };

  updatedFilteredMembers.owners = getUpdatedFilteredMembersEntryForConfluence(
    createTeamForm.gitHubOwners,
    teamMembersValue,
    confluenceMembersValue
  );
  updatedFilteredMembers.contributors = getUpdatedFilteredMembersEntryForConfluence(
    createTeamForm.gitHubContributors,
    teamMembersValue,
    confluenceMembersValue
  );
  updatedFilteredMembers.readers = getUpdatedFilteredMembersEntryForConfluence(
    createTeamForm.gitHubReaders,
    teamMembersValue,
    confluenceMembersValue
  );

  return updatedFilteredMembers;
};

export type TeamResponse = {
  gitHubContributors: { login: string }[];
  gitHubReaders: { login: string }[];
  gitHubOwners: { login: string }[];
  gitHubVisibilityForSwoEmployees: boolean;
};

export async function checkUserOwnership(
  profileData: ProfileInfo | undefined,
  projectService: ProjectService,
  system: string,
  formattedProfile: any,
  hasGithubAdmins: boolean,
  setIsOwner: React.Dispatch<React.SetStateAction<boolean | undefined>>,
  creatorActiveDirectoryId: string | undefined
): Promise<void> {
  if (formattedProfile.authProvider === 'GitHub') {
    try {
      await processProjectTeamInfo(projectService, system, hasGithubAdmins, setIsOwner, profileData);
      return;
    } catch {
      return;
    }
  }
  formattedProfile.id === creatorActiveDirectoryId && setIsOwner(true);
}

export async function processProjectTeamInfo(
  projectService: ProjectService,
  system: string,
  hasGithubAdmins: boolean,
  setIsOwner: React.Dispatch<React.SetStateAction<boolean | undefined>>,
  profileData: { displayName?: string } | undefined
): Promise<TeamResponse> {
  const res = await projectService.getProjectTeamInfo(system);
  const teamData = res as TeamResponse;
  const isOwner = teamData.gitHubOwners.some(data => data.login === profileData?.displayName);
  setIsOwner(isOwner || hasGithubAdmins);
  return teamData;
}
export const handleSetUrls = (
  url: string,
  urlTitle: string,
  urlCategory: string,
  setUrls: Links,
  setUrlTitleError: React.Dispatch<React.SetStateAction<boolean>>
): boolean => {
  if (!URL_PATTERN.test(url)) {
    console.error('Invalid URL format');
    return false;
  }

  if (urlTitle.trim() !== '') {
    setUrls(prevUrls => [
      ...prevUrls,
      { url: new URL(url).href, title: urlTitle, type: 'custom', category: urlCategory },
    ]);
    setUrlTitleError(false);
    return true;
  }

  setUrlTitleError(true);
  return false;
};

export const extractSonarValues = (
  sonarAnnotationsValue: string | undefined | null,
  sonarInstances: SonarInstance[]
): SonarValues => {
  let extractSonarInstance: SonarInstance | undefined | string | null;
  let extractProjectKeySonar: string | undefined;
  if (sonarAnnotationsValue) {
    const splitProjectKeySonarValue = sonarAnnotationsValue.split('/');
    if (splitProjectKeySonarValue.length === 1) {
      extractSonarInstance = '';
      extractProjectKeySonar = splitProjectKeySonarValue[0];
    } else if (splitProjectKeySonarValue.length === 2) {
      const sonarInstanceName = splitProjectKeySonarValue[0];
      const projectKey = splitProjectKeySonarValue[1];
      const sonarInstanceExists = sonarInstances.some(instance => instance.name.trim() === sonarInstanceName.trim());
      if (sonarInstanceExists) {
        extractSonarInstance = sonarInstanceName;
      } else {
        extractSonarInstance = '';
      }
      extractProjectKeySonar = projectKey;
    } else {
      console.log('Sonar project key is invalid');
    }
  } else {
    console.log('Metadata or annotations are undefined');
  }
  return { extractSonarInstance, extractProjectKeySonar };
};

export const successMessage =
  'was successfully updated.\nIt takes the platform about a minute to make the change visible. Until then you will be seeing the old state.\n Reload the page several times until you see your changes.';
