import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { QualityGate } from '@softwareone/plugin-project-management-common/src/types/backstage/catalog/system-entity/qualityGate';

const fontTableHeader = {
  fontSize: '18px',
};
const tableRowStyle = {
  borderBottom: '1px solid #e0e0e0',
};
interface QualityGatesFormProps {
  qualityGates: QualityGate[];
  qualityGateStatusOptions: string[];
  handleInputChange: (index: number, field: string, value: string) => void;
  handleStatusChange: (index: number, value: string | null) => void;
  isGitHubSourcePeople: boolean | undefined;
  artifactsUrlValidation: { [index: number]: boolean };
}

export const QualityGatesItem = ({
  qualityGates,
  qualityGateStatusOptions,
  handleInputChange,
  handleStatusChange,
  artifactsUrlValidation,
  isGitHubSourcePeople,
}: QualityGatesFormProps) => {
  return (
    <Box>
      <TableContainer component={Paper} elevation={1}>
        <Table size="small">
          <TableHead style={tableRowStyle}>
            <TableRow>
              <TableCell style={fontTableHeader}>Name</TableCell>
              <TableCell style={fontTableHeader}>Status</TableCell>
              <TableCell style={fontTableHeader}>Source</TableCell>
              <TableCell style={fontTableHeader}>Artifact</TableCell>
              <TableCell style={fontTableHeader}>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {qualityGates.map((qualityGate, index) => {
              let statusCell, artifactCell, notesCell;
              if (qualityGate.type && qualityGate.type.toLowerCase() === 'automatic') {
                statusCell = <div>{qualityGate.status}</div>;
                artifactCell = <div>{qualityGate.artifacts}</div>;
                notesCell = <div>{qualityGate.notes}</div>;
              } else {
                statusCell = (
                  <Autocomplete
                    options={qualityGateStatusOptions}
                    defaultValue={qualityGate.status}
                    renderInput={params => <TextField {...params} fullWidth variant="standard" />}
                    onChange={(_, value) => handleStatusChange(index, value)}
                    disabled={!isGitHubSourcePeople}
                  />
                );
                artifactCell = (
                  <TextField
                    value={[qualityGate.artifacts]}
                    fullWidth
                    onChange={e => handleInputChange(index, 'artifacts', e.target.value)}
                    variant="standard"
                    error={artifactsUrlValidation[index] === undefined ? false : !artifactsUrlValidation[index]}
                    helperText={
                      artifactsUrlValidation[index] === undefined ||
                      (!artifactsUrlValidation[index] && 'Wrong URL format')
                    }
                  />
                );
                notesCell = (
                  <TextField
                    multiline
                    value={qualityGate.notes}
                    fullWidth
                    onChange={e => handleInputChange(index, 'notes', e.target.value)}
                    variant="standard"
                  />
                );
              }
              return (
                <TableRow key={index}>
                  <TableCell>{qualityGate.name}</TableCell>
                  <TableCell>{statusCell}</TableCell>
                  <TableCell>{qualityGate.source}</TableCell>
                  <TableCell>{artifactCell}</TableCell>
                  <TableCell>{notesCell}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
