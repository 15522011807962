import useAsync from 'react-use/lib/useAsync';
import { useContext } from 'react';
import { AppContext } from '@softwareone/plugin-project-management-react';

export const useOrganizationJiraTeamMembers = () => {
  const {
    services: { jiraService },
  } = useContext(AppContext);

  const { loading, error, value } = useAsync(async () => jiraService.getJiraOrgUsers(), []);
  return { loading, error, value };
};
