import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '@softwareone/plugin-project-management-react';

export const useCollaboratorOption = (searchValue = 'No options') => {
  const [delayedSearchValue, setDelayedSearchValue] = useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [value, setValue] = useState<any | null>(null);
  const [error, setError] = useState<Error | string | null>(null);
  const {
    services: { collaboratorsService },
  } = useContext(AppContext);
  useEffect(() => {
    // ToDo fixme (might be hard)
    // eslint-disable-next-line no-undef
    let timerId: NodeJS.Timeout;
    if (searchValue.length >= 2) {
      timerId = setTimeout(() => {
        setDelayedSearchValue(searchValue);
      }, 250);
    } else {
      setLoading(false);
    }
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [searchValue]);

  useEffect(() => {
    if (delayedSearchValue) {
      const fetchCollaborators = async () => {
        try {
          const responseData = await collaboratorsService.searchCollaboratorByName(delayedSearchValue);
          setValue(responseData);
        } catch (err) {
          let errorMessage = `An unknown error occurred during the API info update`;
          if (err instanceof Error) {
            errorMessage = err.message;
          }
          setError(errorMessage);
        } finally {
          setLoading(false);
        }
      };
      fetchCollaborators();
    }
  }, [delayedSearchValue]);

  return { loading, error, value };
};
