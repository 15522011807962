import { EntityFilter, UserListFilterKind } from '@backstage/plugin-catalog-react';
import { parseEntityRef } from '@backstage/catalog-model';

export class EntityUserFilter implements EntityFilter {
  private constructor(
    readonly value: UserListFilterKind,
    readonly refs?: string[]
  ) {}

  static owned(ownershipEntityRefs: string[]) {
    return new EntityUserFilter('owned', ownershipEntityRefs);
  }

  static all() {
    return new EntityUserFilter('all');
  }

  static starred(starredEntityRefs: string[]) {
    return new EntityUserFilter('starred', starredEntityRefs);
  }

  getCatalogFilters(): Record<string, string[]> {
    if (this.value === 'owned') {
      return { 'relations.ownedBy': this.refs ?? [] };
    }
    if (this.value === 'starred') {
      const starredEntities = this.refs?.map(e => parseEntityRef(e).name);
      return {
        'metadata.name': starredEntities && starredEntities.length > 0 ? starredEntities : [''],
      };
    }
    return {};
  }
  toQueryValue(): string[] {
    return this.refs || [];
  }
}
