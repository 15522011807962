import React, { createContext, useState } from 'react';

type UserFilter = 'All' | 'Owned' | 'My' | 'Starred';

export type UserFilterContextType = {
  userFilter: UserFilter;
  updateUserFilter: (userFilter: UserFilter) => void;
};

type UserFilterContextProviderProps = {
  children: React.ReactNode;
};

export const UserFilterContext = createContext<UserFilterContextType | null>(null);

export const UserFilterContextProvider = ({ children }: UserFilterContextProviderProps) => {
  const [userFilter, setUserFilter] = useState<UserFilter>('All');
  const updateUserFilter = (filter: UserFilter) => setUserFilter(filter);
  return <UserFilterContext.Provider value={{ userFilter, updateUserFilter }}>{children}</UserFilterContext.Provider>;
};
