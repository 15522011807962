import { HttpService } from './http-service';
import { CreateForkedRepository, CreateRepository } from './types';

export class GitHubService {
  constructor(private readonly httpService: HttpService) {}

  public async createForkedRepository(createForkedRepository: CreateForkedRepository) {
    const res = await this.httpService.post('/projects/forked-components', createForkedRepository);
    if (!res.ok) {
      const result = await res.json();
      throw new Error(result.message);
    }
  }
  public async deleteRepository(projectKey: string, componentKey: string) {
    const res = await this.httpService.delete(`/projects/${projectKey}/components/${componentKey}`);
    if (!res.ok) {
      const result = await res.json();
      throw new Error(result.message);
    }
  }
  public async archiveRepository(projectKey: string, componentKey: string, archived: boolean) {
    const res = await this.httpService.put(`/projects/${projectKey}/components/${componentKey}/archived-state`, {
      archived,
    });
    if (!res.ok) {
      const result = await res.json();
      throw new Error(result.message);
    }
  }
  public async createRepository(createRepository: CreateRepository) {
    const res = await this.httpService.post('/projects/components', createRepository);
    if (!res.ok) {
      const result = await res.json();
      throw new Error(result.message);
    }
  }
  public async getTemplates(signal?: AbortSignal) {
    const res = await this.httpService.get('/projects/org/github/template-repositories', signal);
    return res.json();
  }

  public async getAdditionalInfo() {
    const res = await this.httpService.get('/projects/additional-info');
    return res.json();
  }
}
