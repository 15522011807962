import React, { useEffect, useState } from 'react';
import { Box, FormControl } from '@mui/material';
import Select, { SingleValue } from 'react-select';

type organizationUserOptionEntry = { value: string; label: string };

interface SingleValueInputProps {
  label: string;
  data: any[];
  minDataCount?: number;
  value: any;
  onChange: (e: any) => void;
  isDisabled?: boolean;
  placeholder?: string;
}

export const SingleValueInput = (props: SingleValueInputProps) => {
  const [selectedOption, setSelectedOption] = useState<SingleValue<organizationUserOptionEntry>>();

  useEffect(() => {
    setSelectedOption({ label: props.value, value: props.value });
    props.onChange(props.value);
  }, []);

  const options: organizationUserOptionEntry[] = [];
  (props.data as string[]).forEach(entry => {
    options.push({ value: entry, label: entry });
  });

  function handleSelect(eventData: SingleValue<organizationUserOptionEntry>) {
    setSelectedOption(eventData);
    props.onChange(eventData!.label);
  }

  return (
    <FormControl sx={{ flex: 1 }}>
      <Box sx={{ m: 2 }}>
        <div className="dropdown-container">
          <h2>{props.label}</h2>
          <Select
            options={options}
            isDisabled={props.isDisabled}
            placeholder={props.placeholder}
            value={selectedOption?.value ? selectedOption : null}
            onChange={handleSelect}
            isSearchable
            isMulti={false}
          />
        </div>
      </Box>
    </FormControl>
  );
};
