import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';

export const useUserTeamGroups = () => {
  const identityApi = useApi(identityApiRef);
  const { loading, value } = useAsync(async () => {
    const userIdentity = await identityApi.getBackstageIdentity();
    const allTeams: string[] = [];
    const owners: string[] = [];
    const involved = new Set<string>();
    userIdentity.ownershipEntityRefs
      .filter(team => team.startsWith('group:default/'))
      .map(team => team.split('group:default/')[1])
      .forEach(team => {
        allTeams.push(team);
        if (team.endsWith('-owners')) {
          const ownersTeam = team.replace(new RegExp('-owners$'), '');
          owners.push(ownersTeam);
          involved.add(ownersTeam);
        } else if (team.endsWith('-contributors')) {
          involved.add(team.replace(new RegExp('-contributors$'), ''));
        } else {
          involved.add(team);
        }
      });

    return {
      owners: owners.length > 0 ? owners : null,
      involved: involved.size > 0 ? Array.from(involved) : null,
      allTeams: allTeams.length > 0 ? allTeams : null,
    };
  }, []);
  return { value, loading };
};
