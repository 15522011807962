import { QueryEntitiesInitialRequest } from '@backstage/catalog-client';
import { useApi } from '@backstage/core-plugin-api';
import { useMemo, useRef } from 'react';
import useAsync from 'react-use/lib/useAsync';

import { catalogApiRef, useEntityList } from '@backstage/plugin-catalog-react';
import { useUserTeamGroups } from './useUserTeamGroups';
import { isEqual } from 'lodash';

export const useAllEntitiesCount = () => {
  const catalogApi = useApi(catalogApiRef);
  const { filters } = useEntityList();
  const { value: teamGroupsValue, loading: teamGroupsLoading } = useUserTeamGroups();
  type Request = {
    allEntitiesRequest: QueryEntitiesInitialRequest;
    ownedEntitiesRequest: QueryEntitiesInitialRequest;
    myEntitiesRequest: QueryEntitiesInitialRequest;
  };
  const prevRequest = useRef<Request>();
  const request = useMemo(() => {
    const ownersFacet = 'spec.owner';
    const nameFacet = 'metadata.name';
    const memberFacet = 'spec.memberOf';
    const typeFacet = 'spec.type';
    const lifecycleFacet = 'spec.lifecycle';
    if (teamGroupsLoading) {
      return undefined;
    }
    const kindValue = filters.kind?.value || '';
    const allEntitiesRequest: QueryEntitiesInitialRequest = {
      filter: {
        kind: kindValue,
        [typeFacet]: filters.type?.value ?? [],
        [lifecycleFacet]: filters.lifecycles?.values ?? [],
      },
      limit: 1,
    };

    const ownedEntitiesRequest: QueryEntitiesInitialRequest = {
      filter: {
        kind: kindValue,
        [ownersFacet]: teamGroupsValue?.owners ?? [''],
        [typeFacet]: filters.type?.value ?? [],
        [lifecycleFacet]: filters.lifecycles?.values ?? [],
      },
      limit: 1,
    };
    let myEntitiesRequest: QueryEntitiesInitialRequest;

    if (kindValue === 'group') {
      myEntitiesRequest = {
        filter: {
          kind: kindValue,
          [nameFacet]: teamGroupsValue?.allTeams ?? [''],
        },
        limit: 1,
      };
    } else if (kindValue === 'user') {
      myEntitiesRequest = {
        filter: {
          kind: kindValue,
          [memberFacet]: teamGroupsValue?.allTeams ?? [''],
        },
        limit: 1,
      };
    } else {
      myEntitiesRequest = {
        filter: {
          kind: kindValue,
          [ownersFacet]: teamGroupsValue?.involved ?? [''],
          [typeFacet]: filters.type?.value ?? [],
          [lifecycleFacet]: filters.lifecycles?.values ?? [],
        },
        limit: 1,
      };
    }
    const isRequestSame =
      isEqual(prevRequest.current?.allEntitiesRequest, allEntitiesRequest) &&
      isEqual(prevRequest.current?.myEntitiesRequest, myEntitiesRequest) &&
      isEqual(prevRequest.current?.ownedEntitiesRequest, ownedEntitiesRequest);
    if (isRequestSame) {
      return prevRequest.current;
    }
    prevRequest.current = {
      allEntitiesRequest,
      myEntitiesRequest,
      ownedEntitiesRequest,
    };

    return { allEntitiesRequest, ownedEntitiesRequest, myEntitiesRequest };
  }, [filters, teamGroupsLoading]);

  const { value: count, loading } = useAsync(async () => {
    if (!request) {
      return { allEntitiesCount: 0, ownedEntitiesCount: 0, myEntitiesCount: 0 };
    }
    const allEntitiesCountPromise = catalogApi.queryEntities(request.allEntitiesRequest);
    const ownedEntitiesCountPromise = catalogApi.queryEntities(request.ownedEntitiesRequest);
    const myEntitiesCountPromise = catalogApi.queryEntities(request.myEntitiesRequest);
    const [allEntities, ownedEntities, myEntities] = await Promise.all([
      allEntitiesCountPromise,
      ownedEntitiesCountPromise,
      myEntitiesCountPromise,
    ]);
    return {
      allEntitiesCount: allEntities.totalItems,
      ownedEntitiesCount: ownedEntities.totalItems,
      myEntitiesCount: myEntities.totalItems,
    };
  }, [request]);

  return { count, loading };
};
