import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const SuccessProjectCreation = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(0);
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h6">
        The project was created. It will be available in the all projects list on the Home page within a minute.
      </Typography>
      <Button variant="outlined" style={{ marginTop: '25px' }} onClick={handleClick}>
        Create Another Project
      </Button>
    </Box>
  );
};
