import { FetchApi, DiscoveryApi } from '@backstage/core-plugin-api';

export class HttpService {
  private baseURL = '';
  constructor(
    private readonly fetchApi: FetchApi,
    private readonly discoveryApi: DiscoveryApi
  ) {}

  public async get(path: string, signal?: AbortSignal) {
    const URL = await this.buildURL(path);
    return this.fetchApi.fetch(URL, { signal });
  }

  public async post(path: string, body: Object) {
    const URL = await this.buildURL(path);
    return this.fetchApi.fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  public async put(path: string, body: Object) {
    const URL = await this.buildURL(path);
    return this.fetchApi.fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  public async delete(path: string) {
    const URL = await this.buildURL(path);
    return this.fetchApi.fetch(URL, {
      method: 'DELETE',
    });
  }

  private async buildURL(path: string) {
    if (!this.baseURL) {
      this.baseURL = await this.discoveryApi.getBaseUrl('project-management');
    }
    return `${this.baseURL}${path}`;
  }
}
