import { EntityFilter } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';

export class EntityMemberTeamFilter implements EntityFilter {
  constructor(
    readonly values: string[],
    readonly currentUser: string
  ) {}

  getCatalogFilters(): Record<string, string | string[]> {
    return { 'spec.memberOf': this.values };
  }
  filterEntity(entity: Entity): boolean {
    return entity.metadata.name !== this.currentUser;
  }
  toQueryValue(): string[] {
    return this.values;
  }
}
