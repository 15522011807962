import useAsync from 'react-use/lib/useAsync';
import { useContext } from 'react';
import { AppContext } from '@softwareone/plugin-project-management-react';

export const useConfluenceOrganizationUsers = () => {
  const {
    services: { confluenceService },
  } = useContext(AppContext);

  const { loading, error, value } = useAsync(async () => confluenceService.getConfluenceOrgUsers(), []);
  return { loading, error, value };
};
