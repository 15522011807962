import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { Bar } from 'react-chartjs-2';
import { AlertSnackbar, AppContext } from '@softwareone/plugin-project-management-react';
import { Progress } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js/auto';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type ResultItem = {
  averagePrCompletionTime: string;
  month: number;
  year: number;
};
type ChartsData = { x: string; y: number }[];

const LeadTimeChart = () => {
  const [data, setData] = useState<ChartsData>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<'success' | 'error'>('success');
  const [open, setOpen] = useState(false);
  const fetchApi = useApi(fetchApiRef);
  const { entity } = useEntity();
  const { name: currentComponentName } = entity.metadata;
  const { system = '' } = entity.spec || {};

  const projectKey = system;
  const componentKey = currentComponentName;
  const {
    services: { metricsService },
  } = useContext(AppContext);
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (!isMounted) {
          return;
        }
        const resultData: ResultItem[] = await metricsService.getLeadTimeForChanges(projectKey as string, componentKey);
        if (resultData.length > 0) {
          const currentDate = new Date();
          const currentMonth = currentDate.getMonth() + 1;
          const currentYear = currentDate.getFullYear();

          const monthsArray = Array.from({ length: 12 }, (_, index) => {
            const month = currentMonth - index;
            const year = currentYear - Math.floor((currentMonth - index - 1) / 12);

            const adjustedMonth = ((((month - 1) % 12) + 12) % 12) + 1;

            const formattedMonth = adjustedMonth.toString().padStart(2, '0');

            return `${formattedMonth}/${year}`;
          }).reverse();

          const generatedData: ChartsData = [];

          monthsArray.forEach(month => {
            const matchingItem = resultData.find(
              item =>
                item.month === parseInt(month.split('/')[0], 10) && item.year === parseInt(month.split('/')[1], 10)
            );

            const minutes = matchingItem
              ? parseInt(matchingItem.averagePrCompletionTime.match(/\d+/g)![0], 10) * 24 * 60 +
                parseInt(matchingItem.averagePrCompletionTime.match(/\d+/g)![1], 10) * 60 +
                parseInt(matchingItem.averagePrCompletionTime.match(/\d+/g)![2], 10)
              : 0;

            generatedData.push({
              x: month,
              y: minutes,
            });
          });

          monthsArray.forEach(month => {
            const hasData = generatedData.some(data => data.x === month);
            if (!hasData) {
              generatedData.push({
                x: month,
                y: 0,
              });
            }
          });

          setData(generatedData);
        }
        setIsLoading(false);
      } catch (error) {
        if (!isMounted) return;
        console.error('Error fetching metrics:', error);
        setMessage('An error occurred trying to get the current Lead Time metrics');
        setStatus('error');
        setOpen(true);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [projectKey, componentKey, fetchApi]);

  if (isLoading) {
    return <Progress />;
  }

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'start' }}>
          <Typography variant="h6">Lead Time for Changes [min]</Typography>
        </Box>
        {data.length > 0 ? (
          <Box
            sx={{
              mt: 3,
              width: '25%',
            }}
          >
            <Box mb={3}>
              <Bar
                data={{
                  labels: data.map(entry => entry.x),
                  datasets: [
                    {
                      label: 'Lead Time',
                      data: data.map(entry => entry.y),
                      backgroundColor: `rgba(0, 0, 128, 0.5)`,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top' as const,
                    },
                    title: {
                      display: true,
                      text: 'Lead Time for Changes',
                    },
                    tooltip: {
                      callbacks: {
                        label: context => {
                          const minutes = context.parsed.y;
                          const divisor = 24 * 60;
                          const days = Math.floor(minutes / divisor);
                          const hours = Math.floor((minutes % divisor) / 60);
                          const remainingMinutes = minutes % 60;

                          return `Lead Time: ${days}d ${hours}h ${remainingMinutes}m`;
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      type: 'category',
                    },
                    y: {
                      beginAtZero: true,
                      max: (() => {
                        const maxMinutes = Math.max(...data.map(entry => entry.y));
                        let divisor;
                        if (maxMinutes < 100) {
                          divisor = 10;
                        } else if (maxMinutes < 1000) {
                          divisor = 100;
                        } else if (maxMinutes < 10000) {
                          divisor = 1000;
                        } else {
                          divisor = 10000;
                        }
                        return Math.ceil(maxMinutes / divisor) * divisor;
                      })(),
                    },
                  },
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box sx={{ textAlign: 'start', mt: 2, mb: 3 }}>
            <Typography>There is no lead time for changes data</Typography>
          </Box>
        )}
        <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} />
      </Box>
    </Box>
  );
};

export default LeadTimeChart;
