import React, { useState, useEffect, FormEvent, useContext } from 'react';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { MultiValueInputField } from '@softwareone/plugin-project-management-react/src/components/fields/multiValueInputField/MultiValueInputField';
import { useCollaboratorOption } from '../hook/useCollaboratorOption';
import { Progress } from '@backstage/core-components';
import { AlertSnackbar, AppContext } from '@softwareone/plugin-project-management-react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useApi, githubAuthApiRef } from '@backstage/core-plugin-api';
import { checkUserOwnership, extractGroupAdminInfo, UserObject } from '../utils/utils';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { SystemEntity } from '@softwareone/plugin-project-management-common';
import {
  FlexContainer,
  InfoToolTip,
} from '@softwareone/plugin-project-management-react/src/components/infoToolTip/InfoToolTip';

export const CollaboratorsCard = () => {
  const [gitHubContributors, setGitHubContributors] = useState<string[]>([]);
  const [gitHubReaders, setGitHubReaders] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState('');
  const [isOwner, setIsOwner] = useState<boolean | undefined>(false);
  const gitHubApi = useApi(githubAuthApiRef);
  const [open, setOpen] = useState(false);
  const { entity } = useEntity();
  const { backstageIdentity, profile } = useUserProfile();
  const formattedProfile = { id: '', authProvider: '', ...profile };
  const { name: currentComponentName, creatorActiveDirectoryId } = entity.metadata as SystemEntity;

  const componentName = currentComponentName;
  const { system = '' } = entity.spec || {};
  const projectKey = system;
  const {
    services: { collaboratorsService, projectService },
  } = useContext(AppContext);

  useEffect(() => {
    const getCollaborators = async () => {
      try {
        const data = (await collaboratorsService.getCollaborators(projectKey as string, componentName)) as {
          gitHubContributors: string[];
          gitHubReaders: string[];
        };
        setGitHubContributors(data.gitHubContributors);
        setGitHubReaders(data.gitHubReaders);
      } catch (err) {
        console.error('Error fetching collaborators:', err);
        setMessage('An error occurred trying to get the current collaborators');
        setStatus('error');
        setOpen(true);
      }
    };
    projectKey && getCollaborators();
  }, [projectKey]);

  const userObject: UserObject = {
    type: backstageIdentity?.type || '',
    userEntityRef: backstageIdentity?.userEntityRef || '',
    ownershipEntityRefs: backstageIdentity?.ownershipEntityRefs || [],
  };

  const hasGithubAdmins: boolean = extractGroupAdminInfo(userObject);

  useEffect(() => {
    if (typeof system !== 'string' || !profile) {
      return;
    }
    const isUserOwner = async () => {
      await checkUserOwnership(
        await gitHubApi.getProfile(),
        projectService,
        system,
        formattedProfile,
        hasGithubAdmins,
        setIsOwner,
        creatorActiveDirectoryId
      );
    };
    isUserOwner();
  }, [hasGithubAdmins, profile]);

  const { loading, error, value } = useCollaboratorOption(searchValue === '' ? undefined : searchValue);
  const handleInputChange = (inputValue: string) => {
    setSearchValue(inputValue);
  };
  useEffect(() => {
    if (loading) {
      return;
    }
    if (error || !Array.isArray(value)) {
      console.log('Error:', error || new Error(value.message));
      setMessage('An error occurred trying to get all potential team members');
      setStatus('error');
      setOpen(true);
    }
  }, [loading, error, value]);

  const getFormattedTeamMembers = () => {
    return {
      gitHubContributors: gitHubContributors,
      gitHubReaders: gitHubReaders,
    };
  };

  const updateCollaborators = async () => {
    try {
      await collaboratorsService.updateCollaborators({
        projectKey: projectKey as string,
        componentName,
        ...getFormattedTeamMembers(),
      });
      setMessage('Your collaborators have been successfully updated');
      setStatus('success');
      setOpen(true);
    } catch (err) {
      let errorMessage = `An unknown error occurred during the collaborator update`;
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      setMessage(errorMessage);
      console.error('Error:', error);
      setStatus('error');
      setOpen(true);
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await updateCollaborators();
  };

  if (loading) {
    return <Progress />;
  }

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>
      <Paper>
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h6">
            Select external collaborators for the GitHub repository of this component
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Stack sx={{ m: 3 }}>
            <FlexContainer>
              <MultiValueInputField
                label="Contributors"
                data={gitHubContributors}
                placeholder="Select Users"
                setMembers={setGitHubContributors}
                value={value}
                handleInputChange={handleInputChange}
                isDisabled={!isOwner}
              />
              <Box sx={{ mt: 8 }}>
                <InfoToolTip text="The SoftwareOne external GitHub users who can commit changes in the GitHub repository of this component." />
              </Box>
            </FlexContainer>
            <FlexContainer>
              <MultiValueInputField
                label="Readers"
                data={gitHubReaders}
                placeholder="Select Users"
                setMembers={setGitHubReaders}
                value={value}
                handleInputChange={handleInputChange}
                isDisabled={!isOwner}
              />
              <Box sx={{ mt: 8 }}>
                <InfoToolTip text="The SoftwareOne external GitHub users who can only view the GitHub repository of this component." />
              </Box>
            </FlexContainer>
          </Stack>
          {isOwner && (
            <Box sx={{ m: 5 }}>
              <Button variant="outlined" fullWidth type="submit">
                Save
              </Button>
            </Box>
          )}
        </form>
      </Paper>
      <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} />
    </Box>
  );
};
