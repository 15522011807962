import { HttpService } from './http-service';
import { CreateProjectInput } from '@softwareone/plugin-project-management-common/src/types/project-management/input/createProjectInput';
import { UpdateProject, UpdateProjectTeam } from './types';
import { QualityGate } from '@softwareone/plugin-project-management-common/src/types/backstage/catalog/system-entity/qualityGate';

export class ProjectService {
  constructor(private readonly httpService: HttpService) {}

  public async getProjectTeamInfo(projectKey: string) {
    const res = await this.httpService.get(`/projects/${projectKey}/teams`);
    return res.json();
  }
  public async getOrgUsers() {
    const res = await this.httpService.get('/projects/org/team/usernames');
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.message);
    }
    return data;
  }
  public async getCustomers() {
    const data = await this.httpService.get('/projects/customers');
    return data.json();
  }

  public async getQualityGates(projectKey: string) {
    const res = await this.httpService.get(`/projects/${projectKey}/quality-gates`);
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.message);
    }
    return data;
  }

  public async getServiceLines() {
    const res = await this.httpService.get('/projects/service-lines');
    if (!res.ok) {
      const data = await res.json();
      throw new Error(data.message);
    }
    return res.json();
  }

  public async updateQualityGates(projectKey: string, qualityGates: QualityGate[]) {
    const res = await this.httpService.put(`/projects/${projectKey}/quality-gates`, qualityGates);
    if (!res.ok) {
      if (res.status === 502 || res.status === 503) {
        throw new Error('Server error');
      }
      const result = await res.json();
      throw new Error(result.message);
    }
  }

  public async getAuditLogs(projectKey: string) {
    const res = await this.httpService.get(`/projects/${projectKey}/quality-gates/audit-logs`);
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.message);
    }
    return data;
  }

  public async updateProjectTeam(updateProjectTeam: UpdateProjectTeam) {
    const { projectKey, ...rest } = updateProjectTeam;
    const res = await this.httpService.put(`/projects/${projectKey}/teams`, rest);
    if (!res.ok) {
      if (res.status === 502 || res.status === 503) {
        throw new Error('Server error');
      }
      const result = await res.json();
      throw new Error(result.message);
    }
  }
  public async createProject(createProjectInput: CreateProjectInput) {
    const res = await this.httpService.post('/projects', {
      ...createProjectInput,
    });
    if (res.status === 400 || res.status === 409 || res.status === 404 || res.status === 500) {
      const result = await res.json();
      throw new Error(result.message);
    } else if (res.status === 502 || res.status === 503) {
      throw new Error('Server error');
    }
  }

  public async updateProject(updateProject: UpdateProject) {
    const { projectKey, ...restProps } = updateProject;
    const res = await this.httpService.put(`/projects/${projectKey}/info`, {
      ...restProps,
    });
    if (res.status !== 204) {
      if (res.status === 502 || res.status === 503) {
        throw new Error('Server error');
      } else {
        const result = await res.json();
        throw new Error(result.message);
      }
    }
  }
}
