import { createTheme, lightTheme } from '@backstage/theme';
import pageThemes from './pageThemes';

const myTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#2b2b2b',
    },
    secondary: {
      main: '#565a6e',
    },
    error: {
      main: '#8c4351',
    },
    warning: {
      main: '#8f5e15',
    },
    info: {
      main: '#34548a',
    },
    success: {
      main: '#485e30',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    banner: {
      info: '#34548a',
      error: '#8c4351',
      text: '#343b58',
      link: '#565a6e',
    },
    errorBackground: '#8c4351',
    warningBackground: '#8f5e15',
    infoBackground: '#343b58',
    navigation: {
      background: '#f3f3f3',
      indicator: '#8f5e15',
      color: '#2b2b2b',
      selectedColor: '#0000ff',
    },
  },
  defaultPageTheme: 'home',
  fontFamily: 'Arial',
  /* below drives the header colors */
  pageTheme: pageThemes,
});

export default myTheme;
