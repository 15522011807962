import { HttpService } from './http-service';
import { UpdateComponent } from './types';

export class ComponentService {
  constructor(private readonly httpService: HttpService) {}

  public async updateComponent(updateComponent: UpdateComponent) {
    const { projectKey, componentKey, ...rest } = updateComponent;

    const res = await this.httpService.put(`/projects/${projectKey}/components/${componentKey}`, rest);

    if (!res.ok) {
      const json = await res.json();
      throw new Error(json.message);
    }
  }

  public async getSonarInstances() {
    const res = await this.httpService.get('/projects/sonar/instances');
    if (!res.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await res.json();
    return data;
  }
}
