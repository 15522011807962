import { TableColumn, TableProps } from '@backstage/core-components';
import { createContext, ReactNode } from 'react';
import { EntityTableRow } from '../components/features/entity/table/types';

export type CatalogContextType = {
  columns?: TableColumn<EntityTableRow>[];
  actions?: TableProps<EntityTableRow>['actions'];
  tableOptions?: TableProps<EntityTableRow>['options'];
  emptyContent?: ReactNode;
};

export const CatalogContext = createContext<CatalogContextType | null>(null);
