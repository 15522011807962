import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AlertSnackbar, AppContext } from '@softwareone/plugin-project-management-react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { FormGroup } from '@material-ui/core';
import { Box, Button, Checkbox, FormControlLabel, Paper, Typography } from '@mui/material';
import { Progress } from '@backstage/core-components';
import Stack from '@mui/material/Stack';

export const SummarAIzeCard = () => {
  const { entity } = useEntity();
  const { name: componentKey } = entity.metadata;
  const { system: projectKey } = entity.spec || {};
  const [open, setOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<'success' | 'error'>('success');
  const [summarAIzeLoading, setsummarAIzeLoading] = useState(true);
  const {
    services: { summarAIzeService },
  } = useContext(AppContext);

  const getSummarAIzeConfig = useCallback(async () => {
    try {
      const result = await summarAIzeService.getSummarAIzeConfig(projectKey as string, componentKey);
      setIsEnabled(result.enabled as boolean);
      setsummarAIzeLoading(false);
    } catch (err) {
      let errorMessage = `An unknown error occurred during the SummarAIze config fetch`;
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      setsummarAIzeLoading(false);
      setMessage(errorMessage);
      setStatus('error');
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    getSummarAIzeConfig();
  }, [getSummarAIzeConfig]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEnabled(e.target.checked);
  };
  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setsummarAIzeLoading(true);
    try {
      await summarAIzeService.updateSummarAIzeConfig(projectKey as string, componentKey, isEnabled);
      setMessage('SummarAIze config is successfully updated');
      setStatus('success');
      setOpen(true);
      setsummarAIzeLoading(false);
    } catch (err) {
      let errorMessage = `An unknown error occurred during the SummarAIze config update`;
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      setsummarAIzeLoading(false);
      setMessage(errorMessage);
      setStatus('error');
      setOpen(true);
    }
  };

  if (summarAIzeLoading) {
    return <Progress />;
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>
          <Paper sx={{ p: 4 }}>
            <FormGroup>
              <Stack spacing={2}>
                <FormControlLabel
                  control={<Checkbox checked={isEnabled} onChange={handleChange} />}
                  label="SummarAIze"
                />
                <Typography>
                  SummarAIze is an internal AI-based tool for automatic generation of descriptions for the pull requests
                  created in the SoftwareOne GitHub repository. Check to enable it for this repository.
                </Typography>
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              </Stack>
            </FormGroup>
            <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} />
          </Paper>
        </Box>
      </form>
    </>
  );
};
