import React, { useState, useEffect, useContext } from 'react';
import EnvironmentsItem from '@softwareone/plugin-project-management-react/src/components/features/environments/EnvironmentsItem';
import { AlertSnackbar, AppContext } from '@softwareone/plugin-project-management-react';
import { SystemEntity } from '@softwareone/plugin-project-management-common';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Box, Button } from '@material-ui/core';
import { checkUserOwnership, extractGroupAdminInfo, UserObject, successMessage } from '../utils/utils';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { githubAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { Progress } from '@backstage/core-components';
import {
  IP_PATTERN,
  URL_PATTERN,
} from '@softwareone/plugin-project-management-common/src/types/regex-patterns/regexPatterns';
import { EnvironmentType } from '@softwareone/plugin-project-management-common/src/types/backstage/catalog/system-entity/environmentType';
import { AccountableType } from '@softwareone/plugin-project-management-common/src/types/backstage/catalog/system-entity/accountableType';

export type EnvironmentFrontEnd = {
  type: EnvironmentType | '';
  location: string;
  accountable: AccountableType | '';
};

const EnvironmentsCard = () => {
  const { entity } = useEntity();
  const { name: currentSystemName, creatorActiveDirectoryId } = entity.metadata as SystemEntity;
  const projectKey = currentSystemName;
  const { backstageIdentity, profile } = useUserProfile();
  const gitHubApi = useApi(githubAuthApiRef);
  const formattedProfile = { id: '', authProvider: '', ...profile };
  const {
    services: { projectService, projectEnvironmentService },
  } = useContext(AppContext);
  const [newEnvironment, setNewEnvironment] = useState<EnvironmentFrontEnd>({
    type: '',
    location: '',
    accountable: '',
  });
  const [type, setType] = useState<EnvironmentType | ''>('');
  const [location, setLocation] = useState<string>('');
  const [accountable, setAccountable] = useState<AccountableType | ''>('');
  const [environments, setEnvironments] = useState<EnvironmentFrontEnd[]>([]);
  const [nonTeamMembersCanViewEnvironments, setNonTeamMembersCanViewEnvironments] = useState<boolean>(false);
  const [isOwner, setIsOwner] = useState<boolean | undefined>(false);
  const [validationUrlFormat, setValidationUrlFormat] = useState(false);
  const [locationErrors, setLocationErrors] = useState<boolean[]>(new Array(environments.length).fill(false));
  const [submitLoading, setSubmitLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<'success' | 'error'>('success');

  const userObject: UserObject = {
    type: backstageIdentity?.type || '',
    userEntityRef: backstageIdentity?.userEntityRef || '',
    ownershipEntityRefs: backstageIdentity?.ownershipEntityRefs || [],
  };
  const hasGithubAdmins: boolean = extractGroupAdminInfo(userObject);

  useEffect(() => {
    if (!profile) return;
    const isUserOwner = async () => {
      await checkUserOwnership(
        await gitHubApi.getProfile(),
        projectService,
        currentSystemName,
        formattedProfile,
        hasGithubAdmins,
        setIsOwner,
        creatorActiveDirectoryId
      );
    };
    isUserOwner();
  }, [hasGithubAdmins, profile]);

  const addEnvironment = () => {
    if (newEnvironment.type && newEnvironment.location && newEnvironment.accountable) {
      setEnvironments([...environments, newEnvironment]);
      setNewEnvironment({ type: '', location: '', accountable: '' }); // Reset for next input
    }
  };

  useEffect(() => {
    const initialEnvironments: EnvironmentFrontEnd[] = Array.isArray(entity.metadata.environments)
      ? (entity.metadata.environments as Array<any>).map(env => ({
          type: typeof env?.type === 'string' ? env.type : '',
          location: typeof env?.location === 'string' ? env.location : '',
          accountable: typeof env?.accountable === 'string' ? env.accountable : '',
        }))
      : [];
    setEnvironments(initialEnvironments);
  }, [entity.metadata.environments]);

  useEffect(() => {
    const initialNonTeamMembersCanView: boolean =
      typeof entity.metadata.nonTeamMembersCanViewEnvironments === 'boolean'
        ? entity.metadata.nonTeamMembersCanViewEnvironments
        : false;
    setNonTeamMembersCanViewEnvironments(initialNonTeamMembersCanView);
  }, [entity.metadata.nonTeamMembersCanViewEnvironments]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitLoading(true);

    try {
      await projectEnvironmentService.updateEnvironments(projectKey, environments, nonTeamMembersCanViewEnvironments);
      setMessage(`Environments ${successMessage}`);
      setStatus('success');
      setSubmitLoading(false);
      setOpen(true);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error occurred';
      setMessage(errorMessage);
      setStatus('error');
      setSubmitLoading(false);
      setOpen(true);
    }
  };

  const handleChange = (e: EnvironmentFrontEnd) => {
    setType(e.type);
    setLocation(e.location);
    setAccountable(e.accountable);
  };

  const isValidUrlOrIp = (location: string): boolean => {
    return URL_PATTERN.test(location) || IP_PATTERN.test(location);
  };

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  if (submitLoading) {
    return <Progress />;
  }

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <EnvironmentsItem
          onChange={handleChange}
          type={type}
          location={location}
          accountable={accountable}
          addEnvironment={addEnvironment}
          environments={environments}
          setEnvironments={setEnvironments}
          newEnvironment={newEnvironment}
          setNewEnvironment={setNewEnvironment}
          isValidUrl={isValidUrlOrIp}
          validationUrlFormat={validationUrlFormat}
          setValidationUrlFormat={setValidationUrlFormat}
          isOwner={isOwner}
          nonTeamMembersCanViewEnvironments={nonTeamMembersCanViewEnvironments}
          setNonTeamMembersCanViewEnvironments={setNonTeamMembersCanViewEnvironments}
          locationErrors={locationErrors}
          setLocationErrors={setLocationErrors}
        />
        <Box sx={{ pr: '30px', pl: '30px' }}>
          <Button
            variant="outlined"
            fullWidth
            type="submit"
            disabled={validationUrlFormat || !isOwner || locationErrors.includes(true)}
          >
            Save
          </Button>
        </Box>
      </form>
      <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} />
    </Box>
  );
};

export default EnvironmentsCard;
