import React from 'react';
import { Box, MenuItem, Button, FormGroup, Divider } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import { FlexContainer, InfoToolTip } from '../../infoToolTip/InfoToolTip';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import Stack from '@mui/material/Stack';
import EnvironmentsTableItem from './EnvironmentTableItem';
import { Checkbox, FormControlLabel } from '@mui/material';
import { EnvironmentFrontEnd } from '../../../../../../packages/app/src/components/card/EnvironmentsCard';
import { EnvironmentType } from '@softwareone/plugin-project-management-common/src/types/backstage/catalog/system-entity/environmentType';
import { AccountableType } from '@softwareone/plugin-project-management-common/src/types/backstage/catalog/system-entity/accountableType';

interface EnvironmentsItemProps {
  onChange: (e: EnvironmentFrontEnd) => void;
  addEnvironment: () => void;
  setEnvironments: (e: EnvironmentFrontEnd[]) => void;
  environments: EnvironmentFrontEnd[];
  newEnvironment: EnvironmentFrontEnd;
  setNewEnvironment: React.Dispatch<React.SetStateAction<EnvironmentFrontEnd>>;
  type: EnvironmentType | '';
  location: string;
  accountable: AccountableType | '';
  isValidUrl: (urlString: string) => boolean;
  validationUrlFormat: boolean;
  setValidationUrlFormat: React.Dispatch<React.SetStateAction<boolean>>;
  isOwner: boolean | undefined;
  nonTeamMembersCanViewEnvironments: boolean;
  setNonTeamMembersCanViewEnvironments: React.Dispatch<React.SetStateAction<boolean>>;
  locationErrors: boolean[];
  setLocationErrors: React.Dispatch<React.SetStateAction<boolean[]>>;
}

type InputValue = string | null;

export const environmentsTypes: (EnvironmentType | '')[] = Object.values(EnvironmentType);
export const accountableOptions: (AccountableType | '')[] = Object.values(AccountableType);

const buttonStyle = {
  maxHeight: '55px',
};
const EnvironmentsItem: React.FC<EnvironmentsItemProps> = ({
  onChange,
  addEnvironment,
  environments,
  setEnvironments,
  newEnvironment,
  setNewEnvironment,
  isValidUrl,
  validationUrlFormat,
  setValidationUrlFormat,
  isOwner,
  nonTeamMembersCanViewEnvironments,
  setNonTeamMembersCanViewEnvironments,
  locationErrors,
  setLocationErrors,
}) => {
  const handleChange = (key: keyof EnvironmentFrontEnd, newValue: InputValue | null, index?: number) => {
    if (newValue !== null) {
      if (typeof index === 'number' && index >= 0 && index < environments.length) {
        const updatedEnvironments = environments.map((env, envIndex) =>
          envIndex === index ? { ...env, [key]: newValue } : env
        );
        setEnvironments(updatedEnvironments);

        if (key === 'location') {
          const isValid = newValue === '' ? true : isValidUrl(newValue);
          const newLocationErrors = [...locationErrors];
          newLocationErrors[index] = !isValid;
          setLocationErrors(newLocationErrors);
        }
      } else {
        setNewEnvironment(prevState => ({
          ...prevState,
          [key]: newValue,
        }));
        if (key === 'location') {
          const isValid = newValue === '' ? true : isValidUrl(newValue);
          setValidationUrlFormat(!isValid);
        }
      }

      const updatedValues: EnvironmentFrontEnd = {
        ...newEnvironment,
        [key]: newValue,
      };
      onChange(updatedValues);
    }
  };

  const handleNonTeamMembersCanViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setNonTeamMembersCanViewEnvironments(newValue);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>
      <Stack spacing={4} sx={{ m: 4 }}>
        <FlexContainer style={{ maxHeight: '55px' }}>
          <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'space-between' }}>
            <Autocomplete
              options={environmentsTypes}
              value={environmentsTypes.includes(newEnvironment.type) ? newEnvironment.type : null}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(_e: any, newValue: string | null) => handleChange('type', newValue as string)}
              renderInput={params => <TextField {...params} label="Type" />}
              sx={{ width: 'calc(50% - 4px)' }}
              disabled={!isOwner}
              renderOption={(props, option) => (
                <MenuItem {...props} style={{ backgroundColor: props.style?.backgroundColor }}>
                  {option}
                </MenuItem>
              )}
            />
            <TextField
              label="Location"
              value={newEnvironment.location}
              error={validationUrlFormat}
              helperText={validationUrlFormat ? 'Invalid URL or IP address format' : ''}
              disabled={!isOwner}
              onChange={e => handleChange('location', e.target.value)}
              sx={{ width: 'calc(50% - 4px)' }}
            />
            <Autocomplete
              options={accountableOptions}
              value={accountableOptions.includes(newEnvironment.accountable) ? newEnvironment.accountable : null}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(_e: any, newValue: string | null) => handleChange('accountable', newValue as string)}
              renderInput={params => <TextField {...params} label="Accountable" />}
              sx={{ width: 'calc(50% - 4px)' }}
              disabled={!isOwner}
              renderOption={(props, option) => (
                <MenuItem {...props} style={{ backgroundColor: props.style?.backgroundColor }}>
                  {option}
                </MenuItem>
              )}
            />
            <Button disabled={validationUrlFormat || !isOwner} onClick={addEnvironment} style={buttonStyle}>
              <AddIcon />
            </Button>
            <FlexContainer>
              <InfoToolTip text="Choose the environment type, enter its location (URL or IP address) in the location field, select who is accountable for it and click the “+” button to add the environment." />
            </FlexContainer>
          </Stack>
        </FlexContainer>
        {environments.length > 0 && (
          <Box>
            <Divider />
            <EnvironmentsTableItem
              environments={environments}
              setEnvironments={setEnvironments}
              handleChange={handleChange}
              isOwner={isOwner}
              locationErrors={locationErrors}
            />
          </Box>
        )}
        <FlexContainer>
          <FormGroup>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isOwner}
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={nonTeamMembersCanViewEnvironments}
                    onChange={handleNonTeamMembersCanViewChange}
                  />
                }
                label="Non-team members can see project environments"
              />
            </Box>
          </FormGroup>
        </FlexContainer>
      </Stack>
    </Box>
  );
};

export default EnvironmentsItem;
