import React from 'react';
import { EntityTable } from './table/EntityTable';
import { CatalogFilterLayout } from '@backstage/plugin-catalog-react';
import { EntityUserGroupPicker } from './filter/EntityUserGroupPicker';
import { EntityKind } from '../../../types';
import { Content, ContentHeader, CreateButton, SupportButton } from '@backstage/core-components';
import { UserFilterContextProvider } from '../../../context';

type EntityItemProps = {
  entityKind: EntityKind;
};
export const EntityItem = ({ entityKind }: EntityItemProps) => (
  <>
    <Content>
      <ContentHeader title="">
        <CreateButton title="CREATE" to="/createProject" />
        <SupportButton>All your software catalog entities</SupportButton>
      </ContentHeader>
      <CatalogFilterLayout>
        <UserFilterContextProvider>
          <CatalogFilterLayout.Filters>
            <EntityUserGroupPicker entityKind={entityKind} key={entityKind} />
          </CatalogFilterLayout.Filters>
          <CatalogFilterLayout.Content>
            <EntityTable />
          </CatalogFilterLayout.Content>
        </UserFilterContextProvider>
      </CatalogFilterLayout>
    </Content>
  </>
);
