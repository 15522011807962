import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { Bar } from 'react-chartjs-2';
import { AlertSnackbar, AppContext } from '@softwareone/plugin-project-management-react';
import { Progress } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js/auto';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type ChartsData = Record<string, { x: string; y: number }[]>;

const DeploymentFrequencyChart = () => {
  const [data, setData] = useState<ChartsData>({});
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<'success' | 'error'>('success');
  const [open, setOpen] = useState(false);
  const fetchApi = useApi(fetchApiRef);
  const { entity } = useEntity();
  const { name: currentComponentName } = entity.metadata;
  const { system = '' } = entity.spec || {};
  const {
    services: { metricsService },
  } = useContext(AppContext);
  const projectKey = system;
  const componentKey = currentComponentName;

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const resultData = await metricsService.getDeploymentFrequency(projectKey as string, componentKey);
        const transformedData: ChartsData = Object.keys(resultData).reduce((result, key) => {
          const currentDate = new Date();
          const lastTwelveMonths = Array.from({ length: 12 }, (_, index) => {
            const monthDate = new Date(currentDate);
            monthDate.setMonth(currentDate.getMonth() - (11 - index));

            const year = monthDate.getFullYear();
            const month = monthDate.getMonth() + 1;
            const formattedMonth = month < 10 ? `0${month}` : `${month}`;

            return `${formattedMonth}/${year}`;
          });

          result[key] = lastTwelveMonths.map(date => {
            const entry = resultData[key].find(
              (e: { year: number; month: number; executions: number }) => `${e.month}/${e.year}` === date
            );
            return {
              x: date,
              y: entry ? entry.executions : 0,
            };
          });
          return result;
        }, {} as ChartsData);
        setData(transformedData);
        setIsLoading(false);
      } catch (error) {
        if (!isMounted) return;
        console.error('Error fetching metrics:', error);
        setMessage('An error occurred trying to get Deployment Frequency metrics');
        setStatus('error');
        setOpen(true);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [projectKey, componentKey, fetchApi]);

  if (isLoading) {
    return <Progress />;
  }

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const maxYValue = Object.values(data).reduce((max, chartData) => {
    const maxYInChartData = Math.max(...chartData.map(entry => entry.y));
    return Math.max(max, maxYInChartData);
  }, 0);

  return (
    <Box>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'start' }}>
          <Typography variant="h6">Deployment Frequency</Typography>
        </Box>
        {Object.entries(data).length > 0 ? (
          <Box
            sx={{
              mt: 3,
              width: '30%',
            }}
          >
            {Object.entries(data).map(([key, chartData]) => (
              <Box key={key} mb={3} mr={5}>
                <Bar
                  data={{
                    labels: chartData.map(entry => entry.x),
                    datasets: [
                      {
                        label: key,
                        data: chartData.map(entry => entry.y),
                        backgroundColor: `rgba(0, 0, 128, 0.5)`,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top' as const,
                      },
                      title: {
                        display: true,
                        text: `Workflow ${key}`,
                      },
                    },
                    scales: {
                      x: {
                        type: 'category',
                      },
                      y: {
                        beginAtZero: true,
                        max: maxYValue,
                      },
                    },
                  }}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Box sx={{ textAlign: 'start', mt: 2, mb: 3 }}>
            <Typography>There is no deployment frequency data</Typography>
          </Box>
        )}
        <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} />
      </Box>
    </Box>
  );
};

export default DeploymentFrequencyChart;
