import React from 'react';
import { RoutedTabs } from '@backstage/core-components';
import { EntityItem } from '../features/entity/EntityItem';

export const EntityTab = () => (
  <>
    <RoutedTabs
      routes={[
        {
          path: '/projects',
          title: 'Projects',
          children: <EntityItem entityKind="system" />,
        },
        {
          path: '/components',
          title: 'Components',
          children: <EntityItem entityKind="component" />,
        },
        {
          path: '/api',
          title: 'APIs',
          children: <EntityItem entityKind="API" />,
        },
        {
          path: '/teams',
          title: 'Teams',
          children: <EntityItem entityKind="group" />,
        },
        {
          path: '/users',
          title: 'Users',
          children: <EntityItem entityKind="user" />,
        },
      ]}
    />
  </>
);
