import React, { useState, useEffect, useContext } from 'react';
import { Box, Paper, Button } from '@mui/material';
import { githubAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { useOrganizationTeamMembers } from '../hook/useOrganizationTeamMembers';
import { useEntity } from '@backstage/plugin-catalog-react';
import { AlertSnackbar, AppContext, TeamsItem } from '@softwareone/plugin-project-management-react';
import { Progress } from '@backstage/core-components';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { extractGroupAdminInfo, processProjectTeamInfo, UserObject } from '../utils/utils';
import { SystemEntity } from '@softwareone/plugin-project-management-common';

export const TeamCard = () => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [gitHubContributors, setGitHubContributors] = useState<string[]>([]);
  const [gitHubOwners, setGitHubOwners] = useState<string[]>([]);
  const [gitHubReaders, setGitHubReaders] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState('');
  const [isOwner, setIsOwner] = useState<boolean | undefined>(false);
  const [teamLoading, setTeamLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const gitHubApi = useApi(githubAuthApiRef);
  const { entity } = useEntity();
  const { name: currentSystemName, type: projectType, creatorActiveDirectoryId } = entity.metadata as SystemEntity;
  const projectKey = currentSystemName;
  const { backstageIdentity, profile } = useUserProfile();
  const formattedProfile = { id: '', authProvider: '', ...profile };
  const { loading, error, value } = useOrganizationTeamMembers();
  const {
    services: { projectService },
  } = useContext(AppContext);
  const formatInitialTeamMembers = (rawFormat: any[]) => {
    const formattedMembers: string[] = [];
    rawFormat.forEach(entry => {
      formattedMembers.push(entry.login);
    });
    return formattedMembers;
  };

  const getFormattedTeamMembers = () => {
    return {
      gitHubOwners: gitHubOwners,
      gitHubContributors: gitHubContributors,
      gitHubReaders: gitHubReaders,
      gitHubVisibilityForSwoEmployees: isChecked,
    };
  };

  const updateTeamMembers = async () => {
    setTeamLoading(true);
    try {
      await projectService.updateProjectTeam({
        projectKey,
        ...getFormattedTeamMembers(),
      });
      setTeamLoading(false);
      setMessage('Your teams have been successfully updated');
      setStatus('success');
      setOpen(true);
    } catch (err) {
      setTeamLoading(false);
      console.error('Error:', err);
      setMessage('An error occurred during the team update');
      setStatus('error');
      setOpen(true);
    }
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    await updateTeamMembers();
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      console.log('Error:', error);
      setMessage('An error occurred trying to get all potential team members');
      setStatus('error');
      setOpen(true);
    } else if (!Array.isArray(value)) {
      const ex = new Error(value.message);
      console.log('Error:', ex);
      setMessage('An error occurred trying to get all potential team members');
      setStatus('error');
      setOpen(true);
    }
  }, [loading, error, value]);

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const userObject: UserObject = {
    type: backstageIdentity?.type || '',
    userEntityRef: backstageIdentity?.userEntityRef || '',
    ownershipEntityRefs: backstageIdentity?.ownershipEntityRefs || [],
  };

  const hasGithubAdmins: boolean = extractGroupAdminInfo(userObject);

  useEffect(() => {
    if (!profile) return;
    const getTeamInfo = async () => {
      if (formattedProfile.authProvider === 'GitHub') {
        setOpen(false);
        setTeamLoading(true);
        const profileData = await gitHubApi.getProfile();
        try {
          type TeamResponse = {
            gitHubContributors: { login: string }[];
            gitHubReaders: { login: string }[];
            gitHubOwners: { login: string }[];
            gitHubVisibilityForSwoEmployees: boolean;
          };

          const teamData: TeamResponse = await processProjectTeamInfo(
            projectService,
            currentSystemName,
            hasGithubAdmins,
            setIsOwner,
            profileData
          );

          teamData?.gitHubContributors && setGitHubContributors(formatInitialTeamMembers(teamData.gitHubContributors));
          teamData?.gitHubOwners && setGitHubOwners(formatInitialTeamMembers(teamData.gitHubOwners));
          teamData?.gitHubReaders && setGitHubReaders(formatInitialTeamMembers(teamData.gitHubReaders));
          teamData?.gitHubVisibilityForSwoEmployees && setIsChecked(teamData.gitHubVisibilityForSwoEmployees);
          setTeamLoading(false);
          return;
        } catch (err) {
          setTeamLoading(false);
          console.error('Error:', err);
          setMessage('An error occurred trying to get the current teams');
          setStatus('error');
          setOpen(true);
          return;
        }
      }
      if (formattedProfile.id === creatorActiveDirectoryId) {
        setIsOwner(true);
        try {
          const res = await projectService.getProjectTeamInfo(currentSystemName);
          type TeamResponse = {
            gitHubContributors: { login: string }[];
            gitHubReaders: { login: string }[];
            gitHubOwners: { login: string }[];
            gitHubVisibilityForSwoEmployees: boolean;
          };
          const teamData = res as TeamResponse;
          teamData?.gitHubContributors && setGitHubContributors(formatInitialTeamMembers(teamData.gitHubContributors));
          teamData?.gitHubOwners && setGitHubOwners(formatInitialTeamMembers(teamData.gitHubOwners));
          teamData?.gitHubReaders && setGitHubReaders(formatInitialTeamMembers(teamData.gitHubReaders));
          teamData?.gitHubVisibilityForSwoEmployees && setIsChecked(teamData.gitHubVisibilityForSwoEmployees);
          setTeamLoading(false);
          return;
        } catch (err) {
          setTeamLoading(false);
          console.error('Error:', err);
          setMessage('An error occurred trying to get the current teams');
          setStatus('error');
          setOpen(true);
        }
      }
    };
    getTeamInfo();
  }, [hasGithubAdmins, profile]);

  type ChangeData = {
    gitHubOwners: string[];
    gitHubContributors: string[];
    gitHubReaders: string[];
    gitHubVisibilityForSwoEmployees: boolean;
  };
  const handleChange = (e: ChangeData) => {
    setGitHubContributors(e.gitHubContributors);
    setGitHubOwners(e.gitHubOwners);
    setGitHubReaders(e.gitHubReaders);
    setIsChecked(e.gitHubVisibilityForSwoEmployees);
  };
  if (loading || teamLoading) {
    return <Progress />;
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 1,
            }}
          >
            <TeamsItem
              gitHubOwners={gitHubOwners}
              gitHubContributors={gitHubContributors}
              gitHubReaders={gitHubReaders}
              isDisabled={!isOwner}
              allTeamsData={value}
              gitHubVisibilityForSwoEmployees={isChecked}
              projectType={projectType}
              onValidityChange={e => setIsFormValid(e)}
              onChange={e => handleChange(e)}
            />
            <Button sx={{ m: '10px' }} disabled={!isFormValid || !isOwner} type="submit" variant="outlined">
              Save
            </Button>
          </Paper>
          <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} />
        </Box>
      </form>
    </>
  );
};
