import { Observable } from '@backstage/types';
import { DependencyList, useEffect, useState } from 'react';

export function useUpdatingObservable<T>(value: T, observable: Observable<T> | undefined, deps: DependencyList): T {
  const [snapshot, setSnapshot] = useState(value);

  useEffect(() => {
    setSnapshot(value);

    const subscription = observable?.subscribe({
      next: updatedValue => {
        setSnapshot(updatedValue);
      },
      complete: () => {
        subscription?.unsubscribe();
      },
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, deps);

  return snapshot;
}
