import React from 'react';
import { Box } from '@material-ui/core';
import { TeamsItem } from '@softwareone/plugin-project-management-react/src/components/features/teams/TeamsItem';

export type ChangeDataTeam = {
  gitHubOwners: string[];
  gitHubContributors: string[];
  gitHubReaders: string[];
  gitHubVisibilityForSwoEmployees: boolean;
};

type CreateTeamProps = {
  onChange: (e: ChangeDataTeam) => void;
  setIsValid: (e: boolean) => void;
  teamMembersValue: { username: string; email: string }[];
  createTeamForm: ChangeDataTeam;
  projectType: string;
  gitHubVisibilityForSwoEmployees: boolean;
};

export const CreateTeam = ({
  onChange,
  setIsValid,
  teamMembersValue,
  createTeamForm,
  projectType,
  gitHubVisibilityForSwoEmployees,
}: CreateTeamProps) => {
  const handleChange = (e: ChangeDataTeam) => {
    if (projectType === 'Personal' || projectType === 'Internal') {
      onChange({
        gitHubContributors: e.gitHubContributors,
        gitHubOwners: e.gitHubOwners,
        gitHubReaders: e.gitHubReaders,
        gitHubVisibilityForSwoEmployees: true,
      });
      return;
    }
    onChange({
      gitHubContributors: e.gitHubContributors,
      gitHubOwners: e.gitHubOwners,
      gitHubReaders: e.gitHubReaders,
      gitHubVisibilityForSwoEmployees: e.gitHubVisibilityForSwoEmployees,
    });
  };

  const handleValidityChange = (e: boolean) => {
    setIsValid(e);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mr: 4,
        mt: 4,
      }}
    >
      <TeamsItem
        gitHubOwners={createTeamForm.gitHubOwners}
        gitHubContributors={createTeamForm.gitHubContributors}
        gitHubReaders={createTeamForm.gitHubReaders}
        allTeamsData={teamMembersValue}
        gitHubVisibilityForSwoEmployees={gitHubVisibilityForSwoEmployees}
        projectType={projectType}
        onValidityChange={handleValidityChange}
        onChange={e => handleChange(e)}
        isDisabled={false}
      />
    </Box>
  );
};
