import React, { ReactNode } from 'react';
import { CatalogContext, EntityTab } from '@softwareone/plugin-project-management-react';
import { PageWithHeader, TableColumn, TableProps } from '@backstage/core-components';
import { EntityListProvider } from '@backstage/plugin-catalog-react';
import { CatalogTableRow } from '../components/table';

interface HomePageProps {
  columns?: TableColumn<CatalogTableRow>[];
  actions?: TableProps<CatalogTableRow>['actions'];
  tableOptions?: TableProps<CatalogTableRow>['options'];
  emptyContent?: ReactNode;
}
export const HomePage = (props: HomePageProps) => (
  <>
    <PageWithHeader title="SoftwareOne Catalog" themeId="home">
      <EntityListProvider>
        <CatalogContext.Provider value={props}>
          <EntityTab />
        </CatalogContext.Provider>
      </EntityListProvider>
    </PageWithHeader>
  </>
);
