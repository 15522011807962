import { HttpService } from './http-service';
import { UpdateCollaborators } from './types';

export class CollaboratorsService {
  constructor(private readonly httpService: HttpService) {}

  public async updateCollaborators(updateCollaborators: UpdateCollaborators) {
    const { projectKey, componentName, ...rest } = updateCollaborators;
    const res = await this.httpService.put(`/projects/${projectKey}/components/${componentName}/collaborators`, rest);
    if (!res.ok) {
      const result = await res.json();
      throw new Error(result.message);
    }
  }

  public async getCollaborators(projectKey: string, componentName: string) {
    const res = await this.httpService.get(`/projects/${projectKey}/components/${componentName}/collaborators`);
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.message);
    }
    return data;
  }

  public async searchCollaboratorByName(name: string) {
    const res = await this.httpService.get(`/projects/github/users?searchString=${name}`);
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.message);
    }
    return data;
  }
}
