import React from 'react';
import { Box } from '@material-ui/core';
import { ProjectInfoItem } from '@softwareone/plugin-project-management-react/src/components/features/projectInfo/ProjectInfoItem';
import { Industry, Technology } from '@softwareone/plugin-project-management-common';
import { UrlEntry } from '@softwareone/plugin-project-management-react/src/services/types/UpdateProject';
import {
  ProjectStage,
  ServiceLine,
} from '@softwareone/plugin-project-management-common/src/types/backstage/catalog/system-entity/service-line';

export type ChangeDataProjectInfoType = {
  projectType: string;
  oneSalesID: string;
  psaProjectId: string;
  erpProjectNumber: string;
  projectCustomer: string;
  projectTitle: string;
  projectKeyState: string;
  projectDescription: string;
  technologies: Technology[];
  industries: Industry[];
  url: string;
  urlTitle: string;
  urlCategory?: string;
  serviceLines: ServiceLine[];
};

type CreateProjectInfoProps = {
  onChange: (e: ChangeDataProjectInfoType) => void;
  setIsValid: (e: boolean) => void;
  createProjectInfoForm: ChangeDataProjectInfoType;
  technologyOptions: Technology[];
  industryOptions: Industry[];
  handleAddUrl: () => void;
  deleteUrl: (indexToDelete: number) => void;
  urls: UrlEntry[];
  urlTitleError: boolean;
  serviceLines: ServiceLine[];
  selectedServiceLine: ServiceLine | null;
  setSelectedServiceLine: (stages: ServiceLine | null) => void;
  selectedValueServiceLineStage: ProjectStage | null;
  setSelectedValueServiceLineStage: (stages: ProjectStage | null) => void;
};

export const CreateProjectInfo = ({
  onChange,
  setIsValid,
  createProjectInfoForm,
  technologyOptions,
  industryOptions,
  handleAddUrl,
  deleteUrl,
  urls,
  urlTitleError,
  serviceLines,
  selectedServiceLine,
  setSelectedServiceLine,
  selectedValueServiceLineStage,
  setSelectedValueServiceLineStage,
}: CreateProjectInfoProps) => {
  const handleChange = (e: ChangeDataProjectInfoType) => {
    onChange({
      projectType: e.projectType,
      oneSalesID: e.oneSalesID,
      psaProjectId: e.psaProjectId,
      erpProjectNumber: e.erpProjectNumber,
      projectCustomer: e.projectCustomer,
      projectTitle: e.projectTitle,
      projectKeyState: e.projectKeyState,
      projectDescription: e.projectDescription,
      technologies: e.technologies,
      industries: e.industries,
      url: e.url,
      urlTitle: e.urlTitle,
      urlCategory: e.urlCategory,
      serviceLines: e.serviceLines,
    });
  };
  const handleValidityChange = (e: boolean) => {
    setIsValid(e);
    if (createProjectInfoForm.projectType === '') {
      setIsValid(false);
    }
  };

  return (
    <Box>
      <ProjectInfoItem
        isOwner
        onChange={handleChange}
        projectType={createProjectInfoForm.projectType}
        oneSalesID={createProjectInfoForm.oneSalesID}
        psaProjectId={createProjectInfoForm.psaProjectId}
        erpProjectNumber={createProjectInfoForm.erpProjectNumber}
        projectCustomer={createProjectInfoForm.projectCustomer}
        projectTitle={createProjectInfoForm.projectTitle}
        projectKeyState={createProjectInfoForm.projectKeyState}
        projectDescription={createProjectInfoForm.projectDescription}
        technologies={createProjectInfoForm.technologies}
        industries={createProjectInfoForm.industries}
        technologyOptions={technologyOptions}
        industryOptions={industryOptions}
        handleAddUrl={handleAddUrl}
        deleteUrl={deleteUrl}
        url={createProjectInfoForm.url}
        urlTitle={createProjectInfoForm.urlTitle}
        urlCategory={createProjectInfoForm.urlCategory || ''}
        urls={urls}
        onValidityChange={handleValidityChange}
        createMode
        urlTitleError={urlTitleError}
        serviceLines={serviceLines}
        selectedServiceLine={selectedServiceLine}
        setSelectedServiceLine={setSelectedServiceLine}
        setSelectedValueServiceLineStage={setSelectedValueServiceLineStage}
        selectedValueServiceLineStage={selectedValueServiceLineStage}
      />
    </Box>
  );
};
