import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

export const useConfirm = () => {
  const [promise, setPromise] = useState<boolean | { resolve: (value: unknown) => void } | null>(null);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const confirm = (msg: string, titleValue: string) =>
    new Promise(resolve => {
      setMessage(msg);
      setTitle(titleValue);
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    if (typeof promise !== 'boolean') {
      promise?.resolve(true);
    }
    handleClose();
  };

  const handleCancel = () => {
    if (typeof promise !== 'boolean') {
      promise?.resolve(false);
    }
    handleClose();
  };

  const ConfirmationDialog = () => (
    <>
      <Dialog open={promise !== null} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>Yes</Button>
          <Button onClick={handleCancel}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  );
  return { ConfirmationDialog, confirm };
};
