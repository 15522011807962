import { useContext } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { AppContext } from '@softwareone/plugin-project-management-react';

export const useAdditionalInfo = () => {
  const {
    services: { gitHubService },
  } = useContext(AppContext);

  const { loading, error, value } = useAsync(async () => gitHubService.getAdditionalInfo(), []);
  return { loading, error, value };
};
