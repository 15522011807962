import React from 'react';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { FlexContainer, InfoToolTip } from '../../infoToolTip/InfoToolTip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Button } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import { environmentsTypes, accountableOptions } from './EnvironmentsItem';
import { EnvironmentFrontEnd } from '../../../../../../packages/app/src/components/card/EnvironmentsCard';

interface EnvironmentsTableItemProps {
  environments: EnvironmentFrontEnd[];
  setEnvironments: (e: EnvironmentFrontEnd[]) => void;
  handleChange: (key: keyof EnvironmentFrontEnd, newValue: string | null, index?: number) => void;
  isOwner: boolean | undefined;
  locationErrors: boolean[];
}
const EnvironmentsTableItem: React.FC<EnvironmentsTableItemProps> = ({
  environments,
  setEnvironments,
  handleChange,
  isOwner,
  locationErrors,
}) => {
  const removeEnvironment = (index: number) => {
    setEnvironments(environments.filter((_, envIndex) => envIndex !== index));
  };
  return (
    <Box>
      {environments.map((env, index) => (
        <Stack key={index} spacing={4} sx={{ m: 4 }}>
          <FlexContainer key={index}>
            <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'space-between' }}>
              <Autocomplete
                options={environmentsTypes}
                value={env.type}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(_e, newValue) => {
                  handleChange('type', newValue, index);
                }}
                disabled={!isOwner}
                renderInput={params => <TextField {...params} label="Type" disabled />}
                sx={{ width: 'calc(50% - 4px)' }}
              />
              <TextField
                label="Location"
                value={env.location}
                onChange={e => handleChange('location', e.target.value, index)}
                sx={{ width: 'calc(50% - 4px)' }}
                disabled={!isOwner}
                error={locationErrors[index]}
                helperText={locationErrors[index] ? 'Invalid URL or IP address format' : ''}
              />
              <Autocomplete
                options={accountableOptions}
                value={env.accountable}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(_e, newValue) => {
                  handleChange('accountable', newValue, index);
                }}
                disabled={!isOwner}
                renderInput={params => <TextField {...params} label="Accountable" disabled />}
                sx={{ width: 'calc(50% - 4px)' }}
              />
              <Button disabled={!isOwner} onClick={() => removeEnvironment(index)}>
                <RemoveIcon />
              </Button>
              <FlexContainer>
                <InfoToolTip text="You can change the type, location (URL or IP address) and who is accountable for the environment." />
              </FlexContainer>
            </Stack>
          </FlexContainer>
        </Stack>
      ))}
    </Box>
  );
};

export default EnvironmentsTableItem;
