import React, { CSSProperties, ReactNode } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

interface FlexContainerProps {
  children: ReactNode;
  style?: CSSProperties;
}

export const FlexContainer: React.FC<FlexContainerProps> = ({ children, style }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

interface TextToolTipProps {
  text: string;
}

export const InfoToolTip: React.FC<TextToolTipProps> = ({ text }) => {
  const lines = text.split('\n');

  const tooltipContent = (
    <div style={{ whiteSpace: 'pre-line', maxWidth: '300px' }}>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
          {index !== lines.length - 1 && <br />}
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <Tooltip title={tooltipContent}>
      <InfoIcon color="secondary" />
    </Tooltip>
  );
};

export const InfoToolTipList: React.FC<TextToolTipProps> = ({ text }) => {
  const split = text.split('\n');
  return (
    <Tooltip
      title={
        <div>
          {split[0]}
          {split.length > 1 && (
            <ul>
              {split.slice(1).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      }
    >
      <InfoIcon color="secondary" />
    </Tooltip>
  );
};
