import { discoveryApiRef, useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';

export const useProjectManagementApi = () => {
  const discoveryApi = useApi(discoveryApiRef);

  const { value } = useAsync(async () => {
    return await discoveryApi.getBaseUrl('project-management');
  }, [discoveryApi]);

  return { baseUrl: value };
};
