import React, { useState, useEffect, useContext } from 'react';
import { Box, Button } from '@material-ui/core';
import { useEntity } from '@backstage/plugin-catalog-react';
import { AuditLogsTableItem } from '@softwareone/plugin-project-management-react/src/components/features/auditLogs/AuditLogsTableItem';
import { QualityGatesItem } from '@softwareone/plugin-project-management-react/src/components/features/qualityGates/QualityGatesItem';
import { AlertSnackbar, AppContext } from '@softwareone/plugin-project-management-react';
import { checkUserOwnership, extractGitHubSourcePeople, UserObject } from '../utils/utils';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { Progress } from '@backstage/core-components';
import { githubAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { SystemEntity } from '@softwareone/plugin-project-management-common';
import { QualityGate } from '@softwareone/plugin-project-management-common/src/types/backstage/catalog/system-entity/qualityGate';
import { QualityGateStatus } from '@softwareone/plugin-project-management-common/src/types/quality-gates/qualityGateStatus';
import { QualityGateType } from '@softwareone/plugin-project-management-common/src/types/quality-gates/qualityGateType';
import { AuditLog } from '@softwareone/plugin-project-management-common/src/types/audit-logs/auditLog';

type QualityGateData = QualityGate[];

const initialQualityGatesData: QualityGateData = [
  {
    id: '',
    name: '',
    type: QualityGateType.MANUAL,
    source: '',
    status: QualityGateStatus.TODO,
    artifacts: [],
    notes: '',
  },
];

export const QualityGatesCard = () => {
  const [qualityGates, setQualityGates] = useState<QualityGateData>(initialQualityGatesData);
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<'success' | 'error'>('success');
  const [open, setOpen] = useState(false);
  const [isGitHubSourcePeople, setIsGitHubSourcePeople] = useState<boolean | undefined>(false);
  const [artifactsUrlValidation, setArtifactsUrlValidation] = useState<{ [index: number]: boolean }>({});
  const { backstageIdentity, profile } = useUserProfile();
  const formattedProfile = { id: '', authProvider: '', ...profile };
  const {
    services: { projectService },
  } = useContext(AppContext);
  const { entity } = useEntity();
  const gitHubApi = useApi(githubAuthApiRef);
  const { name: currentSystemName, creatorActiveDirectoryId } = entity.metadata as SystemEntity;
  const projectKey = currentSystemName;

  const qualityGateStatusOptions = [
    QualityGateStatus.TODO,
    QualityGateStatus.INPROGRESS,
    QualityGateStatus.PASS,
    QualityGateStatus.FAIL,
  ];

  useEffect(() => {
    const getQualityGates = async () => {
      try {
        setLoading(true);
        const responseData = await projectService.getQualityGates(projectKey as string);
        if (responseData) {
          setQualityGates(responseData);
          setLoading(false);
        } else {
          setLoading(false);
          setStatus('error');
          console.error('Quality Gate data is empty');
        }
      } catch (error) {
        setLoading(false);
        setStatus('error');
        console.error('Error fetching quality gates:', error);
      }
    };
    getQualityGates();
  }, [projectKey]);

  useEffect(() => {
    const getAuditLogs = async () => {
      try {
        setLoading(true);
        const responseData = await projectService.getAuditLogs(projectKey as string);
        if (responseData) {
          setAuditLogs(responseData);
          setLoading(false);
        } else {
          setLoading(false);
          setStatus('error');
          console.error('Quality Gate data is empty');
        }
      } catch (error) {
        setLoading(false);
        setStatus('error');
        console.error('Error fetching quality gates:', error);
      }
    };
    getAuditLogs();
  }, [projectKey]);

  const rowsAuditLogs = auditLogs?.flatMap(auditLog =>
    auditLog.events.map(event => ({
      time: new Date(auditLog.timestamp).toISOString().replace('T', ' ').substring(0, 19),
      username: auditLog.username,
      qualityGate: event.qualityGateName,
      oldValue: event.oldStatus,
      newValue: event.newStatus,
    }))
  );

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      const requestBody = qualityGates
        .filter(qualityGate => qualityGate.type === QualityGateType.MANUAL)
        .map(qualityGate => ({
          id: qualityGate.id,
          name: qualityGate.name,
          type: qualityGate.type,
          source: qualityGate.source,
          status: qualityGate.status,
          artifacts: Array.isArray(qualityGate.artifacts)
            ? qualityGate.artifacts
            : qualityGate.artifacts
              ? [qualityGate.artifacts]
              : [],
          notes: qualityGate.notes,
        }));
      await projectService.updateQualityGates(projectKey, requestBody);
      setMessage('Quality Gates successfully updated');
      setStatus('success');
      setSubmitLoading(false);
      setOpen(true);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error occurred';
      setMessage(errorMessage);
      setStatus('error');
      setSubmitLoading(false);
      setOpen(true);
    }
  };

  const userObject: UserObject = {
    type: backstageIdentity?.type || '',
    userEntityRef: backstageIdentity?.userEntityRef || '',
    ownershipEntityRefs: backstageIdentity?.ownershipEntityRefs || [],
  };

  const hasGitHubSourcePeople: boolean = extractGitHubSourcePeople(userObject);

  useEffect(() => {
    if (!profile) return;
    const isUserGitHubSourcePeople = async () => {
      await checkUserOwnership(
        await gitHubApi.getProfile(),
        projectService,
        currentSystemName,
        formattedProfile,
        hasGitHubSourcePeople,
        setIsGitHubSourcePeople,
        creatorActiveDirectoryId
      );
    };
    isUserGitHubSourcePeople();
  }, [hasGitHubSourcePeople, profile]);

  const isValidUrl = (url: string) => {
    if (!url) return true;
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleInputChange = (index: number, field: string, value: string) => {
    const updatedData = [...qualityGates];
    const updatedQualityGate: QualityGate = { ...updatedData[index], [field]: value };
    updatedData[index] = updatedQualityGate;
    setQualityGates(updatedData);
    if (field === 'artifacts') {
      const isValid = isValidUrl(value);
      setArtifactsUrlValidation(prevState => ({
        ...prevState,
        [index]: isValid,
      }));
      if (!isValid) return;
    }
  };

  const handleStatusChange = (index: number, value: string | null) => {
    handleInputChange(index, 'status', value || '');
  };

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  if (loading || submitLoading) {
    return <Progress />;
  }

  const isInvalidUrl = Object.values(artifactsUrlValidation).some(valid => !valid);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <QualityGatesItem
          qualityGates={qualityGates}
          qualityGateStatusOptions={qualityGateStatusOptions}
          handleInputChange={handleInputChange}
          handleStatusChange={handleStatusChange}
          artifactsUrlValidation={artifactsUrlValidation}
          isGitHubSourcePeople={isGitHubSourcePeople}
        />
        <Box sx={{ pt: 3 }}>
          <Button variant="outlined" fullWidth type="submit" disabled={!isGitHubSourcePeople || isInvalidUrl}>
            Save
          </Button>
        </Box>
        {rowsAuditLogs.length === 0 ? null : <AuditLogsTableItem rows={rowsAuditLogs} />}
      </form>
      <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} />
    </Box>
  );
};
