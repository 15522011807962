import { CompoundEntityRef, Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { useApiHolder } from '@backstage/core-plugin-api';
import { useMemo } from 'react';
import {
  EntityRefPresentation,
  EntityRefPresentationSnapshot,
  entityPresentationApiRef,
} from './EntityPresentationApi';
import { defaultEntityPresentation } from './defaultEntityPresentation';
import { useUpdatingObservable } from './useUpdatingObservable';

/**
 * Returns information about how to represent an entity in the interface.
 *
 * @public
 * @param entityOrRef - The entity to represent, or an entity ref to it. If you
 *   pass in an entity, it is assumed that it is NOT a partial one - i.e. only
 *   pass in an entity if you know that it was fetched in such a way that it
 *   contains all of the fields that the representation renderer needs.
 * @param context - Optional context that control details of the presentation.
 * @returns A snapshot of the entity presentation, which may change over time
 */
export function useEntityPresentation(
  entityOrRef: Entity | CompoundEntityRef | string,
  context?: {
    defaultKind?: string;
    defaultNamespace?: string;
  }
): EntityRefPresentationSnapshot {
  // Defensively allow for a missing presentation API, which makes this hook
  // safe to use in tests.
  const apis = useApiHolder();
  const entityPresentationApi = apis.get(entityPresentationApiRef);

  const deps = [entityPresentationApi, JSON.stringify(entityOrRef), JSON.stringify(context || null)];

  const presentation = useMemo<EntityRefPresentation>(() => {
    if (!entityPresentationApi) {
      const fallback = defaultEntityPresentation(entityOrRef, context);
      return { snapshot: fallback, promise: Promise.resolve(fallback) };
    }

    return entityPresentationApi.forEntity(
      typeof entityOrRef === 'string' || 'metadata' in entityOrRef ? entityOrRef : stringifyEntityRef(entityOrRef),
      context
    );
  }, deps);

  return useUpdatingObservable(presentation.snapshot, presentation.update$, [presentation]);
}
